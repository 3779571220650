import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'helvetica';
    font-size: 14px;
    margin: 0;
    min-height: 100vh;
    background-color: white;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`

export const CaptionBody = styled.span`
  font-weight: 100;
  font-size: 21px;
`
