/* eslint-disable @typescript-eslint/no-unused-vars */
import { Anchor, Card, Image } from '@enterprise-ui/canvas-ui-react'
import { isEmpty } from 'validate.js'
import notificationAnnouncement from '../JoyrideImages/notificationsAnnouncement.png'
import carrierContactInformation from '../JoyrideImages/CarrierContactInformation.png'
import emailNotificationsAnnouncement from '../JoyrideImages/emailNotificationsAnnouncement.png'

const newLocationUpdateFeatures = [
  {
    title: 'Email Notifications',
    description: (
      <div style={{ lineHeight: '1.5em', textAlign: 'left' }}>
        <div style={{ padding: '25px', color: 'black' }}>
          <div className="hc-mb-sm"></div>

          <div className="hc-pb-sm">
            <strong>Daily Email Notifications</strong> – ShipIQ now has the
            ability to email a user daily to highlight all new notifications
            from the day prior!
          </div>
          <div className="hc-pb-sm">Benefits</div>
          <ul className="hc-pb-sm">
            <li>
              Reduce Unnecessary Logins: Vendors with less than daily activity
              in ShipIQ can rely on email notifications to know when there is
              something new such as new purchase orders to review or shipments
              with new load assignments.
            </li>
            <li>
              Streamlined Process: Vendor users can click on the email link for
              a specific notification to be brought directly to ShipIQ with a
              search already executed for the relevant shipments.
            </li>
            <li>
              Customizable Experience: With this feature launch, we are also
              introducing Preferences which allow a user to subscribe or
              unsubscribe to email notifications.
            </li>
          </ul>
          <br />
        </div>
      </div>
    ),
    src: emailNotificationsAnnouncement,
    width: '600px',
    height: '600px',
    component: 'img',
  },
  {
    title: 'Survey for ShipIQ Users',
    description: (
      <div style={{ lineHeight: '1.5em', textAlign: 'left' }}>
        <div style={{ padding: '25px', color: 'black' }}>
          <div className="hc-mb-sm"></div>

          <div>Attention ShipIQ Users,</div>
          <div className="hc-pb-sm">
            We are excited to announce that our quarterly survey is now open! We
            look forward to gathering your valuable feedback on our application.
            The survey will help us understand how to best serve and improve the
            user experience, and your input is crucial in this process.
          </div>
          <div>
            The survey should only take 5 to 10 minutes of your time and can be
            accessed via the following link:{' '}
            <Anchor
              target="blank"
              rel="noreferrer"
              href="https://survey.medallia.com/?target-sus&product=target_sus_product_shipiq"
            >
              https://survey.medallia.com/?target-sus&product=target_sus_product_shipiq
            </Anchor>
          </div>
          <div className="hc-pb-sm">
            We appreciate your continued support and look forward to hearing
            your thoughts. Thank you for taking the time to complete the survey.
          </div>
          <div className="hc-pb-sm">Best Regards,</div>
          <div>ShipIQ Product Team</div>
        </div>
      </div>
    ),
  },
  {
    title: 'New Load Milestone Notifications',
    description: (
      <div style={{ lineHeight: '1.5em', textAlign: 'left' }}>
        <div style={{ padding: '25px', color: 'black' }}>
          <div className="hc-mb-sm"></div>

          <div className="hc-pb-sm">
            We heard your feedback on Feature Upvote and are excited to announce
            that new notifications have been added:
          </div>
          <ul className="hc-pb-sm">
            <li>Load Assignment</li>
            <li>Pickup Date Updates</li>
            <li>Carrier Updates</li>
          </ul>
          <br />
          <div className="hc-pb-sm">
            Clicking on a notification will take you to a page where you can
            view the shipments associated with it.
          </div>
          <div>
            If you want to revisit the same loads later, you can leave the
            notification as unread. Once you’ve finished reviewing the shipments
            and no longer need the notification, simply click "Mark as read" to
            remove it from your lineup.
          </div>
        </div>
      </div>
    ),
    src: notificationAnnouncement,
    width: '800px',
    height: '600px',
    component: 'img',
  },
  {
    title: 'Carrier Contact Information',
    description: (
      <div style={{ lineHeight: '1.5em', textAlign: 'left' }}>
        <div style={{ padding: '25px', color: 'black' }}>
          <div>
            <div className="hc-pb-sm">
              The newest feature in ShipIQ is Carrier Contact Information. We
              have designed this feature to help you stay informed and connected
              with the carriers responsible for transporting your packages to
              their final destination.
            </div>
            <div className="hc-pb-sm">
              The new Contact Information feature provides you with direct
              contact details for your assigned carrier, enabling seamless
              communication and better collaboration. With this feature, you
              can:
            </div>
            <ul className="hc-pb-lg">
              <li>
                Access a carrier's contact information easily within the ShipIQ
                application
              </li>
              <li>
                Reach out to the carrier for urgent queries or real-time updates
              </li>
              <li>
                Facilitate any necessary coordination to ensure smooth pickups
                and deliveries
              </li>
            </ul>
            <div>
              To access the Contact Information feature, click on a load number
              to access details about the load, and click on the "Carrier
              Details" line to expand the contact details for the carrier
              assigned to your package.
            </div>
          </div>
        </div>
      </div>
    ),
    src: carrierContactInformation,
    width: '600px',
    height: '600px',
    component: 'img',
  },
]

const renderCardMedia = (step: any) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Card corners="none">
      {!isEmpty(step.src) && (
        <Image
          alt={step.alt}
          src={step.src}
          width={step.width}
          height={step.height}
          type={step.type}
        />
      )}
    </Card>
  </div>
)

const defaultWidth = 1000

export const featureAnnouncementSteps = [
  //FEATURE ANNOUNCEMENT CAROUSEL STEPS HERE, EXAMPLE:
  {
    target: 'body',
    placement: 'center',
    content: (
      <>
        <div>
          <div className="hc-ta-center">
            <div>
              <h3>{newLocationUpdateFeatures[0].title}</h3>
              {renderCardMedia(newLocationUpdateFeatures[0])}
            </div>
          </div>
          {newLocationUpdateFeatures[0].description}
        </div>
      </>
    ),
    styles: {
      options: {
        width: defaultWidth,
      },
    },
  },
  {
    target: 'body',
    placement: 'center',
    content: (
      <>
        <div>
          <div className="hc-ta-center">
            <div>
              <h3>{newLocationUpdateFeatures[1].title}</h3>
              {renderCardMedia(newLocationUpdateFeatures[1])}
            </div>
          </div>
          {newLocationUpdateFeatures[1].description}
        </div>
      </>
    ),
    styles: {
      options: {
        width: defaultWidth,
      },
    },
  },
  {
    target: 'body',
    placement: 'center',
    content: (
      <>
        <div>
          <div className="hc-ta-center">
            <div>
              <h3>{newLocationUpdateFeatures[2].title}</h3>
              {renderCardMedia(newLocationUpdateFeatures[2])}
            </div>
          </div>
          {newLocationUpdateFeatures[2].description}
        </div>
      </>
    ),
    styles: {
      options: {
        width: defaultWidth,
      },
    },
  },

  // Add additional steps before the reminder below
  {
    target: '[aria-label="explore new features"]',
    content:
      'At any time you can click this announcement button to reopen this feature tour.',
    disableBeacon: true,
  },
]
