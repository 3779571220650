import React, { useState } from 'react'
import {
  Card,
  Divider,
  ExpandableSection,
  Heading,
} from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'
import { useGlobalContext } from 'context/GlobalContext'
import { Welcome } from 'components/Welcome/Welcome'

const CallOutBanner = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 12px 20px;
  width: 70%;
  z-index: 1;
`

type ExpandableInformation = {
  testId: string
  header: string
  content: string[]
}

type Faqs = {
  topic: string
  questions: Question[]
}

type Question = {
  question: string
  answers: string[]
}

export const faqs: Faqs[] = [
  {
    topic: 'Purchase Order Processing',
    questions: [
      {
        question: 'My purchase order is not in ShipIQ\n',
        answers: [
          'There are three common scenarios why a PO will not be present in ShipIQ:\n',
          "1.) Unsupported purchase order types\n - The are a variety of purchase orders that are not processed by ShipIQ. These include: a) import POs, b) Prepaid POs, and c) POs that are entirely written for store locations (' Ship to Store').",
          "2.) Vendor is not live - ShipIQ will only process a PO if it was created after the vendor's go-live date in ShipIQ. In the case of domestic bulk POs, ShipIQ will process a PO if it is allocated after the vendor's go-live date.\n",
          '3.) Unallocated Domestic Bulk PO\n - ShipIQ will process a domestic bulk only after it has been allocated. This means a purchase order will not be present in ShipIQ immediately after it is created, but only after it is allocated.\n',
          'If a PO is not present in ShipIQ and it does not fall into one of these reasons, there may have been an error in processing.\n',
        ],
      },
      {
        question: 'The origin location for my purchase order is not correct.',
        answers: [
          "ShipIQ processes VOP rewrites. ShipIQ will process VOP rewrites as soon as it has been notified by the upstream purchase order system of the change to the purchase order's VOP.",
        ],
      },
      {
        question:
          'A shipment for a destination location is missing for a purchase order in ShipIQ.',
        answers: [
          'ShipIQ processes purchase orders and creates shipments in response to the following events: 1) initial PO creation; 2) VOP rewrite; 3) PO reinstate. As of August 2023, ShipIQ only creates shipments for destination locations of the following types: RDC, FLC, UDC, and CDC. If a destination location was not present on a PO at the time of one of these PO creation events (or was present but is not one of the four supported location types), then it will not receive a shipment in ShipIQ. For example, if a location is added to PO after initial creation, it will not have a shipment in ShipIQ.',
          'Vendors are encouraged to create a manual shipment for a PO in cases when this happens',
        ],
      },
      {
        question: 'Can SIQ handle POs written to an IW (Import Warehouse)?',
        answers: [
          'Yes, ShipIQ can process purchase orders written to import warehouse (600, 3800, 3807, 3810).',
        ],
      },
      {
        question:
          'My PO shows Could Not Find Vendor Details under Vendor and Location Details.',
        answers: [
          "If a PO was written to a vendor's origin location that exists at the time a PO was written and initially processed by ShipIQ, but then was later deleted by a vendor from VMM, the location details will no longer be present in ShipIQ to the user.",
          'If a Vendor no longer needs shipments for this PO, they can manually cancel shipments or work with the IA to cancel the purchase order.',
          'If the Vendor needs the shipment for this PO, shipments will still be routed by TM and should be processed normally by ShipIQ.\n',
        ],
      },
      {
        question:
          'My purchase order has two locations for the same destination location, even though the weight is under 45000 lbs. Why is that the case?\n',
        answers: [
          'If total weight for a given location is above 45,000 lbs, ShipIQ will automatically create multiple shipments for that location. If a the vendor origin location for the PO is set to ship "palletized" product, the weight limit is not 45,000 lbs, but 43,752 lbs (to account for pallet weight).',
        ],
      },
    ],
  },
  {
    topic: 'Shipment Routing and Load Details',
    questions: [
      {
        question: 'Why is a shipment not yet in Transportation Manager (TM)?',
        answers: [
          'ShipIQ sends shipments to Transportation Manager when it is within 4 business days of its Ship End Date. If a shipment is not yet within 4 business days of the ship end date, it will not yet be present in TM. It will still have a status of On Hold for Processing in ShipIQ during this time',
          'Note: ShipIQ does not process Maintain or Update events for purchase orders. If an IA has updated a ship window for a purchase order, ShipIQ will not process that update. Shipments will keep the initial ship start and end dates and will be sent to TM for routing with the initial ship end date.',
        ],
      },
      {
        question: 'Why has the shipment not been routed and placed on a load?',
        answers: [
          '1) If the shipment has not yet been sent to TM (described in the answer above, "Why is a shipment not yet in Transportation Manager (TM)?"), the shipment will not yet be assigned to a load.',
          "2) It is possible the routing for the shipment has not yet occurred in TM. Routing for a shipment is expected to occur by the date specified in the 'Final Routing Expected By' field. If this date has passed and a shipment is in TM but not yet routed, please contact @tm-routing in the ShipIQ Activation Channel for help.",
        ],
      },
      {
        question:
          'Why does the shipment not have an Inventory Ready Date (IRD)?',
        answers: [
          'The "Inventory Ready Date" displayed for Shipments in ShipIQ are generated by a separate IRD service. It is possible that for some locations for a PO, that the IRD service did not provide an IRD. In those cases, ShipIQ will not show an IRD for the shipment. Despite this, ShipIQ is still able to process the PO and will send the shipments to TM for routing. In these kinds of cases, ShipIQ\'s routing guidance to TM will follow a similar structure to VRS shipments in which the pickup window mimics the ship window and the delivery window extends out 20 days to allow for a wide range of optimization opportunities.',
          "A special case where IRDs are not present for a PO's shipments are domestic bulk POs. There are cases when the IRD service provides two or fewer distinct IRDs for all locations for a given PO. In these cases where the IRD is not valid, ShipIQ will ignore the IRD provided and fall back to the VRS routing logic described above.",
        ],
      },
      {
        question: 'How is the Time Left To Review date Calculated?',
        answers: [
          'The "Time Left to Review" field displays how many days are left between the current day and the date by which a vendor is expected to review and release all shipments for a purchase order.',
          'The "expected release date" for a purchase order is calculated from two dates from the purchase order: 1) "Approval Date" and 2) "Ship End Date." The formula for calculating the "expected release date" is to take the later date when comparing 1) "Approval Date + 2 Business Days" and 2) "Ship End Date - 5 Business Days." For the purpose of these calculations, "business days" is defined as weekdays (Monday through Friday).',
          'Note, if a purchase order is "domestic bulk," "Allocation Date" is used instead of "Approval Date" for the calculations described above.',
          'Consider the following example. If a Purchase Order has an "Approval Date" of 2/5/2024 and a "Ship End Date" of 2/19/2024, the calculation would compare "2/5/24 + 2 Business Days which = 2/7/24" to "2/19/24 - 5 Business Days which = 2/12/24." In this case, the "expected release date" would be 2/12/24, because it is later than 2/7/24.',
        ],
      },
      {
        question: 'How does ShipIQ determine the status of a shipment?',
        answers: [
          '"Content Review Required" is the initial status of a newly created shipment. The shipment is awaiting review from a vendor.',
          '"On Hold For Routing" means that the shipment is has been reviewed and submitted, but it is still waiting to be sent for routing so that a pickup date can be retrieved.',
          '"Processing Modification" means that there are pending modifications for a shipment that have not yet completed.',
          '"Routing In Progress" means that the vendor has acknowledged the shipment, it has been sent to Transportation Manager for routing, but is not yet assigned to a load.',
          '"Awaiting Carrier Acceptance" is assigned when Transportation Manager (TM) has assigned the shipment to a load, and a carrier has been identified, but the carrier has not yet accepted the load.',
          '"Awaiting Carrier Assignment" means that the shipment has been assigned to a load, but carrier selection is still processing.',
          '"Carrier Accepted, Awaiting Pickup" is set once the shipment has been routed and optimized on a load and the carrier has accepted the load.',
          '"Picked Up" is set once an EDI214 has been received for pickup from the carrier. This means that the shipment is on a truck on its way to Target. ',
          '"Past Pickup" is a status that is set if an EDI214 is not received for pickup, and at least one day has passed since expected pickup date.',
          '"Failed Modification" means that the shipment optimization failed within Transportation Manager.',
          '"Processing Cancel" means that a cancellation request has been received. This can be a little slow because shipments, purchase orders, and load details are all handled on separate systems as distinct entities, and it can take some processing time for all aspects of the shipment to cancel together.',
          '"Cancelled" status means that the shipment is cancelled, but a Purchase Order is still present.',
          '"Purchase Order Cancelled" status means that the purchase order is cancelled. ShipIQ has initiated the process to cancel all shipments within the system.',
          '"Small Package" is set if the shipment is small enough to be sent via small package service which is typically deemed to be under 150lbs"',
          '"Status Not Found" indicates that something didn\'t quite go according to plan within ShipIQ. Please reach out to ShipIQ support if you see this status.',
        ],
      },
      {
        question:
          "What logic does ShipIQ use to the pickup and delivery windows that get sent to Transportation Manager following a vendor's shipment review and submission?",
        answers: [
          'There are five scenarios that may be present to logically determine the pickup and delivery windows within ShipIq',
          '##### Scenario 1:',
          'The user provides a pickup date',
          '## Pickup From: the user provided pickup date',
          '## Pickup To: the user provided pickup date',
          '## Delivery From: the user provided pickup date',
          '## Delivery To: the user provided pickup date + 20 days',
          '##### Scenario 2:',
          'Note: the only way for there to be no provided pickup date is if the system automatically tries to update or create a first mile booking. User-created bookings must provide a shipment date for their manually created shipments.',
          '1. User did not provide pickup date AND',
          '2. Inventory Ready Date is not present AND',
          '3. Earliest pickup date (today + 2 business days) is before original ship end date',
          'Calculated pickup date is determined by the time of day that the vendor released the shipment. If before 11:00am central time, then the **calculated pickup date** is the vendor release date + 2 business days, and if released after 11:00am central time, then it is the vendor release date + 3 business days.',
          '## Pickup From: the later of (Original Shipment End Date) or (Calculated Pickup Date + 2 business days)',
          '## Pickup To: Original Ship End Date',
          '## Delivery From: Original Ship End Date',
          '## Delivery To: Original Ship End Date + 20 days',
          '##### Scenario 3:',
          '1. User did not provide pickup date AND',
          '2. Inventory Ready Date is not present AND',
          '3. Earliest pickup date (today + 2 business days) is after original ship end date:',
          'Calculated pickup date is determined by the time of day that the vendor released the shipment. If before 11:00am central time, then the calculated pickup date is the vendor release date + 2 business days. If released after 11:00am central time, then it is the vendor release date + 3 business days.',
          '## Pickup From: calculated pickup date',
          '## Pickup To: calculated pickup date',
          '## Delivery From: calculated pickup date',
          '## Delivery To: calculated pickup date + 20 days',
          '##### Scenario 4:',
          '1. User did not provide pickup date AND',
          '2. Inventory Ready Date is present AND',
          '3. Earliest pickup date (today + 2 business days) is before original ship end date',
          '## Pickup From:',
          'Calculated pickup date is determined by the time of day that the vendor released the shipment. If before 11:00am central time, then the calculated pickup date is the vendor release date + 2 business days, and if released after 11:00am central time, then it is the vendor release date + 3 business days.',
          'This calculated pickup date is compared to the Original ship end date.',
          'If the calculated pickup date (+2 or +3 bus. days) is after the original ship end date, the Pickup Date is the __Vendor Release date + 2 business days.__',
          'If the calculated pickup date is before the original ship end date, the Pickup Date is the __calculated pickup date__.',
          '## Pickup To: IRD + 2 business days',
          '## Delivery From: IRD - 1 business day',
          '## Delivery To: IRD + 2 business days',
          '##### Scenario 5:',
          '1. User did not provide pickup date AND',
          '2. Inventory Ready Date is present AND',
          '3. earliest pickup date (today + 2 business days) is after original ship end date',
          '## Pickup From:',
          'Calculated pickup date is determined by the time of day that the vendor released the shipment. If before 11:00am central time, then the calculated pickup date is the vendor release date + 2 business days, and if released after 11:00am central time, then it is the vendor release date + 3 business days.',
          'This calculated pickup date is compared to the Original ship end date.',
          'If the calculated pickup date (+2 or +3 bus. days) is after the original ship end date, the Pickup From Date is __the Vendor Release date + 2 business days__.',
          'If the calculated pickup date is before the original ship end date, the Pickup From Date is __the calculated pickup date__.',
          '## Pickup To: Inventory Ready Date + 2 business days + difference (in days) between original ship end date and earliest pickup date (today + 2 days)',
          '## Delivery From: Inventory Ready Date - 1 business day',
          '## Delivery To: Inventory Ready Date + 2 business days + difference (in days) between original ship end date and earliest pickup date (today + 2 days)',
        ],
      },
    ],
  },
  {
    topic: 'Vendor Details and Locations',
    questions: [
      {
        question:
          'A Vendor updated location information in VMM (e.g., adding a new logistics contact) and the updates are not yet present in ShipIQ',
        answers: [
          'Vendor location updates should flow automatically from VMM to ShipIQ. Data will sync about once per minute from VMM to ShipIQ. Once every 5 minutes, those updates are then pushed by ShipIQ to Transportation Manager (TM).',
          'If the time period above has passed and new vendor location updates are still not present in ShipIQ, please reach out to the VMM and ShipIQ teams for support.',
        ],
      },
      {
        question:
          'A new vendor was added through transportation go-live and the vendor is not present yet in ShipIQ.',
        answers: [
          'After adding a new Vendor to the ShipIQ go-live list through "Transportation Admin," it can take up to 5 minutes for the new vendor to show up in ShipIQ and all location syncs (between VMM, ShipIQ, and TM) to complete.',
        ],
      },
      {
        question:
          'The default commodity drop down does not display options for a location',
        answers: [
          "The commodity types that a user will see in the dropdown for a given origin location is determined by the 'Shipment Profile' that is set for the location. If a desired commodity type is not present, a new Shipment Profile needs to be assigned to the location, which has access to the desired commodity.",
          "'Shipment Profile' setup can be performed through the 'Transportation Admin' application.",
        ],
      },
      {
        question:
          'I want to select a specific default commodity, but it is not available in the commodity dropdown for the location and instead shows "Shipment Profile Type Not Found".',
        answers: [
          "If a user is unable to select any default commodity for a given location, and instead receives the 'Shipment Profile Type Not Found' message, this means the location does not have a shipment profile assigned. A 'Shipment Profile' is needed for a location before a default location can be selected.",
          "'Shipment Profile' setup can be performed through the Transportation Admin application.",
        ],
      },
    ],
  },
  {
    topic: 'User Access to ShipIQ',
    questions: [
      {
        question:
          'A user for a vendor is unable to access ShipIQ. After logging in through POL, they see the following message: "The Business Partner Id is not Permissioned to Use ShipIQ"\n',
        answers: [
          'A user who experiences this access issue is not setup with the proper entitlements to use ShipIQ. Please reach out to the VMM team to confirm that the Vendor is set up in VMM and that all users are setup as a contact for that Vendor.\n',
        ],
      },
    ],
  },
  // Add more topics and questions as needed
]

const ShipIqExpandable = (props: ExpandableInformation) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <>
      <div className={'hc-mh-4x hc-mb-dense'}>
        <Card elevation={isExpanded ? '1' : '0'}>
          <ExpandableSection
            expanded={isExpanded}
            onExpand={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            <p
              className={'hc-fs-sm'}
              onMouseOver={() => {
                setIsHovered(!isHovered)
              }}
              onMouseOut={() => {
                setIsHovered(false)
              }}
              style={{
                color: isHovered ? '#1976d2' : 'black',
                fontWeight: '200',
              }}
              data-testid={props.testId}
            >
              {props.header}
            </p>

            <ExpandableSection.Content className={'hc-ml-1x'}>
              {props.content.map((item, index) => (
                <p
                  key={`${props.testId}-answer-${index}`}
                  data-testid={`${props.testId}-answer-${index}`}
                >
                  {item}
                </p>
              ))}
            </ExpandableSection.Content>
          </ExpandableSection>
          {!isExpanded ? <Divider /> : null}
        </Card>
      </div>
    </>
  )
}

export const FAQ = () => {
  const { globalState } = useGlobalContext()
  const {
    userInfo: { isExternal },
  } = globalState
  const vendorList = globalState?.vendorDetails?.vendors || []
  const isValidUser = !isExternal || (isExternal && vendorList?.length)

  if (isValidUser) {
    return (
      <>
        <div className={'hc-ta-center hc-pa-sm'}>
          <CallOutBanner>
            <Card
              elevation={1}
              className="hc-bg-highlight hc-pa-normal"
              data-testid="faq-callout-notification"
            >
              <p className="hc-clr-alert">
                ShipIQ has new features getting added all the time. We're happy
                to assist our business partners as they're navigating the
                system. Below you can find the answers to the most frequently
                asked questions we're getting from our partners at Target
              </p>
            </Card>
          </CallOutBanner>
        </div>

        {faqs.map((topic, _index) => (
          <div
            key={`${topic.topic}`}
            data-testid={`${topic.topic}`}
            className="hc-mb-dense"
          >
            <Heading size={5} className="hc-ph-4x hc-mt-2x">
              {topic.topic}
            </Heading>
            {topic.questions.map((faq, faqIndex) => (
              <div key={`${topic.topic}-question-${faqIndex}`}>
                <ShipIqExpandable
                  testId={`${topic.topic}-question-${faqIndex}`}
                  header={faq.question}
                  content={faq.answers}
                />
              </div>
            ))}
          </div>
        ))}
        <br />
      </>
    )
  } else {
    return (
      <Welcome message="Looks like you do not have access to any vendors" />
    )
  }
}
