import styled from 'styled-components'

export const BottomActionsBar = styled.div`
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border-width: 1px 0 0 0;
  background: #424242;
  z-index: 6;
`
