import axios from 'axios'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { VendorIdType } from 'common/types'
import { getUiServicesEndpoint } from './utils'

export const vendorAttributesEndpoint = async (vendorId: VendorIdType) => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/vendor_attributes/vendor/${vendorId}?key=${key}`
  try {
    return await axios({
      method: 'get',
      url: endpoint,
    })
  } catch (error: any) {
    const message = `Failed to fetch vendor attributes for vendor ${vendorId}`
    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: error?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
    throw new Error(message)
  }
}
