import { GlobalState } from 'common/types'
import { createContext, useContext, useReducer } from 'react'

const initialState: GlobalState = {
  drawer: {
    contentProps: null,
    template: null,
    drawerProps: null,
  },
  vendorDetails: { vendors: [] },
  filters: { shipmentManagement: {} },
  initialFilterQueriesLoaded: false,
  isBusinessPartner: true,
  isDevMode:
    process.env.REACT_APP_ENV === 'dev' ||
    localStorage.getItem('isDebugMode') === 'true',
  isDebugMode: localStorage.getItem('isDebugMode') === 'true',
  isLoading: null,
  isUnverified: true,
  modal: {
    props: null,
    template: null,
  },
  selectedVendor: 'Select Vendor',
  selectedDepartments: [],
  queries: {},
  tempFilterQuery: {},
  userInfo: {
    isExternal: null,
    isInternal: null,
    isSet: false,
    transportationProfile: {
      read: null,
      write: null,
    },
  },
}

type Action = (
  | { type: 'SET_FILTERS' }
  | { type: 'SET_INITIAL_FILTER_QUERIES' }
  | { type: 'SET_BASE_ENDPOINT' }
  | { type: 'REMOVE_NOTIFICATION' }
  | { type: 'SET_USER_PERMISSIONS' }
  | { type: 'SET_MODAL' }
  | { type: 'SET_DRAWER' }
  | { type: 'SET_VENDORS' }
  | { type: 'SET_TEMP_FILTER_QUERY' }
  | { type: 'SET_UNVERIFIED_FLAG' }
  | { type: 'SET_LOADING_BAR' }
  | { type: 'SET_BASIC_LOCS_VENDORS' }
) & { payload?: any }
interface GlobalContextInterface {
  globalState: any
  globalDispatch: any
}

const GlobalContext = createContext<GlobalContextInterface>({
  globalState: {},
  globalDispatch: {},
})

function globalReducer(state: any, action: Action) {
  switch (action.type) {
    case 'SET_FILTERS': {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    }
    case 'SET_INITIAL_FILTER_QUERIES':
      return {
        ...state,
        filters: action.payload,
        initialFilterQueriesLoaded: true,
      }
    case 'SET_BASE_ENDPOINT':
      return {
        ...state,
        baseEndpoint: action.payload,
      }
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notification: {
          message: undefined,
          type: undefined,
        },
      }
    case 'SET_USER_PERMISSIONS': {
      return {
        ...state,
        userInfo: {
          ...action.payload,
        },
      }
    }
    case 'SET_MODAL':
      return {
        ...state,
        modal: action.payload,
      }
    case 'SET_DRAWER':
      return {
        ...state,
        drawer: action.payload,
      }
    case 'SET_VENDORS':
      return {
        ...state,
        vendorDetails: action.payload,
      }

    case 'SET_BASIC_LOCS_VENDORS':
      return {
        ...state,
        basicLocVendorsDetails: action.payload,
      }
    case 'SET_TEMP_FILTER_QUERY':
      return {
        ...state,
        tempFilterQuery: action.payload,
      }
    case 'SET_UNVERIFIED_FLAG':
      return {
        ...state,
        isUnverified: action.payload,
      }
    case 'SET_LOADING_BAR':
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      break
  }
}

export const GlobalProvider = (props: any) => {
  const { children } = props
  const [state, dispatch] = useReducer(
    globalReducer,
    props.initialState || initialState,
  )

  const value = { globalState: state, globalDispatch: dispatch }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(GlobalContext)
  if (context === undefined) {
    throw new Error('useGlobal must be used within a GlobalProvider')
  }
  return context
}
