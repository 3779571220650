import { Dialog } from '@enterprise-ui/canvas-ui-react'
import { useEffect, FC } from 'react'
import { useUpdatesAvailable } from './UpdatesAvailableContext'

const recheckTimeout = 1000 * 60 * 2

export const UpdatesNotification: FC = () => {
  const { isUpdateAvailable, updateAssets, isDeferred, setIsDeferred } =
    useUpdatesAvailable()

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (isDeferred) {
      interval = setInterval(() => setIsDeferred(false), recheckTimeout)
    }

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeferred])

  const deferUpdate = () => {
    setIsDeferred(true)
  }

  return isUpdateAvailable && !isDeferred ? (
    <Dialog
      isVisible
      headingText="A new version of ShipIQ is available!"
      approveButtonText="Update Now"
      onApprove={updateAssets}
      onRefuse={deferUpdate}
      refuseButtonText="Not now, remind me in 2 minutes"
    />
  ) : null
}
