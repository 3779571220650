import { useGlobalContext } from 'context/GlobalContext'
import { useAnalytics } from '@praxis/component-analytics'

type AnalyticsWrapperProps = {
  apiKey: string
  appName: string
}

export const useAnalyticsWrapper = (configuration?: AnalyticsWrapperProps) => {
  const {
    globalState: { isDevMode },
  } = useGlobalContext()
  const analyticsHooks = useAnalytics(configuration)

  if (!isDevMode) {
    return analyticsHooks
  }
}
