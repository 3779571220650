import { noop } from 'common/noop'

const UPDATEFOUND = 'updatefound'
const STATECHANGE = 'statechange'
const INSTALLED = 'installed'
const CONTROLLER_CHANGE = 'controllerchange'

const UPDATE_CHECK_INTERVAL = 1000 * 30

const checkForUpdate = (reg: ServiceWorkerRegistration) => {
  return setInterval(() => {
    // noop here to prevent an unhandledexception error
    // to be logged every 30 sec
    reg.update().catch(noop)
  }, UPDATE_CHECK_INTERVAL)
}

export type ServiceWorkerRegistrationConfig = {
  onUpdate: (registration: ServiceWorkerRegistration) => void
  onWaiting: (waitingServiceWorker: ServiceWorker) => void
}

export const registerServiceWorker = (
  config: ServiceWorkerRegistrationConfig,
) => {
  if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((reg: ServiceWorkerRegistration) => {
        const interval = checkForUpdate(reg)
        // check to see if there is an existing service worker in the waiting state
        if (reg.waiting) {
          config.onWaiting(reg.waiting)
        }
        reg.addEventListener(UPDATEFOUND, () => {
          // get the "installing" service worker
          const installingWorker = reg.installing

          if (installingWorker) {
            installingWorker.addEventListener(STATECHANGE, () => {
              if (installingWorker.state === INSTALLED) {
                if (navigator.serviceWorker.controller) {
                  if (interval) {
                    clearInterval(interval)
                  }

                  config.onUpdate(reg)
                }
              }
            })
          }
        })
      })
      .catch((err) => {
        console.warn('service-worker registration failed: ', err)
      })

    // Once the service worker is activated and the controller has changed we can reload the app
    navigator.serviceWorker.addEventListener(CONTROLLER_CHANGE, () => {
      window.location.reload()
    })
  }
}
