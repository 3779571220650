import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { DestinationByVendorIds } from '../common/types'

export const destinationsByVendorIdsEndpoint: (
  vendorIds?: number[],
) => Promise<string> = async (vendorIds?: number[]) => {
  const { key, url } = await getUiServicesEndpoint()
  return vendorIds && vendorIds?.length
    ? `${url}/v1/shipments/destinations?key=${key}&vendor_ids=${vendorIds.join(
        ',',
      )}`
    : `${url}/v1/shipments/destinations?key=${key}`
}

export const getDestinationByVendorIdsEndpoint: (
  vendorIds?: number[],
) => Promise<DestinationByVendorIds[]> = async (vendorIds) => {
  const endpoint = await destinationsByVendorIdsEndpoint(vendorIds)
  try {
    const response = await axios.get(endpoint)
    return response?.data?.destinations || []
  } catch (err: any) {
    throw new Error('Failed to fetch destinationByVendorIds')
  }
}
