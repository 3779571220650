import axios from 'axios'

import { getUiServicesEndpoint } from './utils'
import { FIELD_MAP, getMappedValue } from './utils'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { PatchProductsVariables } from '../common/types'
import { PRODUCTS_MESSAGE } from '../common/constants'

export const patchProductEndpoint = async ({
  row,
  field,
  value,
}: PatchProductsVariables) => {
  const { department, locationId, vendorId, isPalletsStackable } = row
  const includePalletsStackable =
    field === 'isPalletized' && value === 'No' && isPalletsStackable === 'Yes'
  const message = includePalletsStackable
    ? PRODUCTS_MESSAGE.PALLETS_STACKABLE_SET
    : PRODUCTS_MESSAGE.DEFAULT

  const body = {
    ...(includePalletsStackable && { pallets_stackable: false }),
    ...{ [FIELD_MAP[field]]: getMappedValue(field, value) },
  }
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/locations/${locationId}/vendors/${vendorId}/departments/${department}?key=${key}`
  try {
    const response = await axios({
      method: 'patch',
      url: endpoint,
      data: body,
    })

    return {
      response,
      locationId: locationId,
      message: message,
    }
  } catch (err: any) {
    const message = 'Failed to update product'
    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err.response.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw Error(message)
  }
}
