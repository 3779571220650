import { Button, Dialog, Card, Heading } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

const OfflineDialog = (_props: any) => {
  const [isOnline, setOfflineStatus] = React.useState(true)

  window.addEventListener('load', () => {
    function updateOfflineStatus() {
      setOfflineStatus(navigator.onLine)
    }

    window.addEventListener('online', updateOfflineStatus)
    window.addEventListener('offline', updateOfflineStatus)
  })

  const forceRefresh = () => {
    window.location.reload()
  }

  return (
    <Dialog open={!isOnline}>
      <Heading id="alert-dialog-title">Ope</Heading>
      <Card elevation={0}>
        <span>Lost internet connection.</span>
      </Card>
      <Card elevation={0} className="hc-ta-right">
        <Button onClick={forceRefresh} type="primary">
          Try Again
        </Button>
      </Card>
    </Dialog>
  )
}

export default OfflineDialog
