import styled from 'styled-components'
import uniq from 'lodash/uniq'

import { entries, keys, get } from 'lodash'
import { ShipmentRowInterface } from 'common/types'

import FlexBox from 'components/FlexBox'
import Summary from './summary'
import {
  ShipmentAttributes,
  anyShipmentAttributeUpdated,
  isShipmentUpdated,
} from './utils'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useShipmentManagementContext } from '../../../context/ShipmentManagementContext'
import {
  Heading,
  Chip,
  Table,
  Card,
  Button,
} from '@enterprise-ui/canvas-ui-react'

const StyledTableData = styled(Table.Data)`
  vertical-align: top;
`

const StyledTableHead = styled(Table.Head)`
  th {
    color: #757575;
  }
`
const StyledDiv = styled(FlexBox)`
  color: #1976d2;
`

const getStatus = (tgtBookingId: string, status: string) => {
  switch (status) {
    case 'Content Review Required':
      return (
        <Chip
          key={`${tgtBookingId}-contentsReviewed`}
          size="dense"
          color="success"
        >
          Contents Reviewed
        </Chip>
      )
    default:
      return status
  }
}

export default function ModifedTable(props: any) {
  const { shipments } = props
  const purchaseOrderNumbers = uniq(
    shipments.map((shipment: any) => {
      return shipment.purchaseOrderNumber
    }),
  )
  const { shipmentManagementDispatch } = useShipmentManagementContext()

  function handleRemoval(item: ShipmentRowInterface) {
    if (item.toBeCancelled) {
      shipmentManagementDispatch({
        type: 'UPDATE_SHIPMENT',
        payload: {
          field: 'toBeCancelled',
          purchaseOrderNumber: item.purchaseOrderNumber,
          department: item.department,
          shipmentId: item.tgtBookingId,
          value: false,
        },
      })
    }
    if (item.isReviewed) {
      shipmentManagementDispatch({
        type: 'UPDATE_SHIPMENT',
        payload: {
          field: 'isReviewed',
          purchaseOrderNumber: item.purchaseOrderNumber,
          department: item.department,
          shipmentId: item.tgtBookingId,
          value: false,
        },
      })
    }
    keys(ShipmentAttributes).forEach((field: string) => {
      if (isShipmentUpdated(item, field)) {
        shipmentManagementDispatch({
          type: 'UPDATE_SHIPMENT',
          payload: {
            field: field[0].toLowerCase() + field.slice(1),
            purchaseOrderNumber: item.purchaseOrderNumber,
            department: item.department,
            shipmentId: item.tgtBookingId,
            value: get(
              item,
              `original_${field[0].toLowerCase() + field.slice(1)}`,
            ),
          },
        })
      }
    })
  }

  const _renderUpdatedFields = (item: ShipmentRowInterface) => {
    if (item.toBeCancelled) {
      return (
        <>
          <StyledTableData xs={2}>
            <StyledDiv>Cancelling Shipment #</StyledDiv>
          </StyledTableData>

          <StyledTableData xs={2}>
            <StyledDiv>{item.tgtBookingId}</StyledDiv>
          </StyledTableData>
        </>
      )
    }

    if (!anyShipmentAttributeUpdated(item)) {
      return (
        <>
          <StyledTableData xs={2}>
            <StyledDiv>No fields updated</StyledDiv>
          </StyledTableData>
          <StyledTableData xs={2}></StyledTableData>
        </>
      )
    }

    return (
      <>
        <StyledTableData xs={2}>
          {entries(ShipmentAttributes).map(([key, value]: [string, string]) => {
            if (!isShipmentUpdated(item, key)) {
              return null
            }

            return (
              <StyledDiv key={`label-${key}-${item.bookingId}`}>
                {value}
              </StyledDiv>
            )
          })}
          {!!item.lastUpdatedBy && <StyledDiv>Last Updated By</StyledDiv>}
        </StyledTableData>

        <StyledTableData xs={2}>
          {keys(ShipmentAttributes).map((key) => {
            const fieldName = key[0].toLowerCase() + key.slice(1)
            const fieldValue = get(item, fieldName)

            if (!isShipmentUpdated(item, key)) {
              return null
            }

            return (
              <StyledDiv key={`value-${key}-${item.bookingId}`}>
                {fieldValue}
              </StyledDiv>
            )
          })}
          {!!item.lastUpdatedBy && <StyledDiv>{item.lastUpdatedBy}</StyledDiv>}
        </StyledTableData>
      </>
    )
  }

  return (
    <>
      <Summary shipments={shipments} />
      {purchaseOrderNumbers.map((purchaseOrderNumber: any) => {
        return (
          <Card
            elevation={1}
            className="hc-pa-sm hc-pb-lg"
            data-testid={`po-${purchaseOrderNumber}`}
            key={purchaseOrderNumber}
          >
            {purchaseOrderNumber && (
              <Heading size={5}>Purchase Order {purchaseOrderNumber}</Heading>
            )}
            <br />
            <Table>
              <StyledTableHead>
                <Table.Row>
                  <Table.Header xs={2} className="hc-ta-left">
                    Shipment
                  </Table.Header>
                  <Table.Header xs={1} className="hc-ta-left">
                    DC
                  </Table.Header>
                  <Table.Header xs={2} className="hc-ta-left">
                    Status
                  </Table.Header>
                  <Table.Header xs={2} className="hc-ta-left">
                    Load
                  </Table.Header>
                  <Table.Header xs={2} className="hc-ta-left">
                    Updated Fields
                  </Table.Header>
                  <Table.Header xs={2} className="hc-ta-left">
                    Updated Value
                  </Table.Header>
                  <Table.Header xs={1} className="hc-ta-right"></Table.Header>
                </Table.Row>
              </StyledTableHead>
              <Table.Body>
                {shipments
                  .filter((shipment: ShipmentRowInterface) => {
                    return shipment.purchaseOrderNumber === purchaseOrderNumber
                  })
                  .map((item: ShipmentRowInterface) => {
                    const {
                      currentShipmentStatus,
                      destLocCode,
                      loadId,
                      tgtBookingId,
                      toBeCancelled,
                    } = item

                    return (
                      <Table.Row key={tgtBookingId}>
                        <StyledTableData xs={2} className="hc-ta-left">
                          {tgtBookingId}
                        </StyledTableData>
                        <StyledTableData xs={1} className="hc-ta-left">
                          {destLocCode}
                          {!destLocCode && '—'}
                        </StyledTableData>
                        <StyledTableData xs={2} className="hc-ta-left">
                          {toBeCancelled && (
                            <Chip
                              key={`${tgtBookingId}-toBeCancelled`}
                              size="dense"
                              style={{
                                backgroundColor: '#ae570f',
                                color: 'white',
                              }}
                            >
                              To Be Cancelled
                            </Chip>
                          )}
                          {!toBeCancelled &&
                            currentShipmentStatus &&
                            getStatus(tgtBookingId, currentShipmentStatus)}
                        </StyledTableData>
                        <StyledTableData xs={2} className="hc-ta-left">
                          {loadId && <span>{loadId}</span>}
                          {!loadId && 'Not Assigned'}
                        </StyledTableData>

                        {/* TWO COLUMNS */}
                        {_renderUpdatedFields(item)}

                        <StyledTableData xs={1} className="hc-ta-right">
                          <Button
                            type="ghost"
                            iconOnly
                            aria-label="cancel change"
                          >
                            <EnterpriseIcon
                              icon={CancelIcon}
                              tabIndex={0}
                              onClick={() => handleRemoval(item)}
                              data-testid="undo-button"
                            />
                          </Button>
                        </StyledTableData>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </Card>
        )
      })}
    </>
  )
}
