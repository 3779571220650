import styled from 'styled-components'
import React from 'react'

interface FlexBoxInterface {
  align?: string
  children?: React.ReactNode
  className?: string
  direction?: string
  height?: string
  justify?: string
  style?: any
  onClick?: () => void
  rowGap?: string
  columnGap?: string
  'data-testid'?: string
}

const Wrapper = styled.div<FlexBoxInterface>`
  align-items: ${(props) => {
    return props.align
  }};
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => {
    return props.direction
  }};
  height: ${(props) => {
    return props.height
  }};
  justify-content: ${(props) => {
    return props.justify
  }};
  width: 100%;
  row-gap: ${(props) => {
    return props.rowGap
  }};
  column-gap: ${(props) => {
    return props.columnGap
  }};
`

const FlexBox = (props: FlexBoxInterface) => {
  return <Wrapper {...props}>{props.children}</Wrapper>
}

export default FlexBox
