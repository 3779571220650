import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import {
  UpdateNotificationUserRequest,
  UpdateNotificationUserResponse,
  Notifications,
  UserNotificationPreferencesResponse,
  ClearNotificationRequest,
  UpdateUserNotificationPreferencesRequest,
} from 'common/types'

export const getNotifications: (vendorIds?: number[]) => Promise<{
  user_managed_notifications: Notifications[]
  system_managed_notifications: Notifications[]
}> = async (vendorIds?: number[]) => {
  const { key, url } = await getUiServicesEndpoint()
  if (vendorIds && vendorIds.length) {
    try {
      const response = await axios.get(`${url}/v1/notifications`, {
        params: {
          vendor_ids:
            vendorIds && vendorIds.length ? vendorIds.join(',') : undefined,
          key,
        },
      })
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  } else {
    return Promise.resolve({
      user_managed_notifications: [],
      system_managed_notifications: [],
    })
  }
}

export const putNotificationUser: (
  request: UpdateNotificationUserRequest,
) => Promise<UpdateNotificationUserResponse> = async (
  request: UpdateNotificationUserRequest,
) => {
  try {
    const { url, key } = await getUiServicesEndpoint()
    const body = {
      user_id: request.userId,
      email: request.email,
    }
    const response = await axios.put(`${url}/v1/notifications/user`, body, {
      params: {
        key,
      },
    })
    const createNotificationUserResponse: UpdateNotificationUserResponse = {
      userId: response.data.user_id,
      email: response.data.email,
      createTs: response.data.create_ts,
    }
    return Promise.resolve(createNotificationUserResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const clearNotification: (
  request: ClearNotificationRequest,
  // returning <any> because the response data isn't relevant
) => Promise<any> = async (request: ClearNotificationRequest) => {
  try {
    const { url, key } = await getUiServicesEndpoint()
    const body = {
      user_id: request.userId,
      notification_id: request.notificationId,
    }
    const response = await axios.post(
      `${url}/v1/notifications/clear_notification`,
      body,
      {
        params: {
          key,
        },
      },
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUserPreferences: (
  request: UpdateUserNotificationPreferencesRequest,
  userId: string,
  // returning <any> because the response data isn't relevant
) => Promise<any> = async (request, userId) => {
  try {
    const { url, key } = await getUiServicesEndpoint()
    const body = {
      subscribed_to_email_notifications: request.subscribedToEmailNotifications,
    }
    const response = await axios.patch(
      `${url}/v1/notifications/user/${userId}/preferences`,
      body,
      {
        params: {
          key,
        },
      },
    )
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getUserPreferences: (
  userId: string,
) => Promise<UserNotificationPreferencesResponse> = async (userId) => {
  try {
    const { url, key } = await getUiServicesEndpoint()
    const response = await axios.get(
      `${url}/v1/notifications/user/${userId}/preferences`,
      {
        params: {
          key,
        },
      },
    )
    const userPreferencesResponse: UserNotificationPreferencesResponse = {
      userId: response.data.user_id,
      subscribedToEmailNotifications:
        response.data.subscribed_to_email_notifications,
    }
    return Promise.resolve(userPreferencesResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}
