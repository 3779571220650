import uniq from 'lodash/uniq'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import Logo from '../../media/shipiq.svg'
import apiConfig from 'config/apiConfig'

export const Unauthorized = () => {
  const analyticsWrapper = useAnalyticsWrapper()

  const entitlements = uniq([
    ...apiConfig.entitlements.tgtInternalRead,
    ...apiConfig.entitlements.tgtInternalWrite,
    ...apiConfig.entitlements.polRead,
    ...apiConfig.entitlements.polWrite,
  ]).flat()

  analyticsWrapper?.trackTaskDone({
    name: 'ShipIQ login',
    status: 'authorization failed',
  })

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Logo width="200" />
      <br />
      ShipIQ requires one of the following entitlements
      <br />
      <br />
      <strong>{`${entitlements
        .filter(
          (entitlement) =>
            entitlement && !entitlement.toString().includes('ADMIN'),
        )
        .join(', ')}`}</strong>
    </div>
  )
}
