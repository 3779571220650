import { Grid, Layout } from '@enterprise-ui/canvas-ui-react'

export const LayoutSimple = (props) => {
  return (
    <>
      <Layout.Body
        id={'mfe-content-body'}
        data-testid="simpleMfeLayout"
        includeRail={true}
      >
        <Grid.Container>
          <Grid.Item xs={12}>{props.component}</Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}
