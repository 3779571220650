import axios from 'axios'
import { get } from 'lodash'
import { getUiServicesEndpoint } from './utils'
import {
  ShipmentModificationVariables,
  ShipmentRowInterface,
} from '../common/types'
import dayjs from 'dayjs'
import { pickupDateChangeReasons } from 'constants/pickupDateChangeReason'

export const getVendorRelease = (
  vendorRelease?: string,
  isModified?: boolean,
) => {
  if (vendorRelease === 'REREVIEW_REQUIRED' && isModified) {
    return 'REREVIEW_REQUIRED'
  }
  return 'VENDOR_ACKNOWLEDGED'
}

export const transformShipmentsToModificationRequests = (
  shipments: Array<ShipmentRowInterface>,
) => {
  return {
    modification_requests: shipments.map((shipment: ShipmentRowInterface) => {
      const {
        tloRefId,
        appointmentNumber,
        bookingId,
        bookingVersion,
        bol,
        carton,
        commodityCode,
        isModified,
        origLocCode,
        pallet,
        pickupDate,
        pickupReasonCode,
        totalVol,
        totalWt,
        vendorRelease,
        volUom,
        wtUom,
        vop,
      } = shipment

      const hasPickupOverride =
        !!pickupReasonCode &&
        pickupReasonCode !==
          pickupDateChangeReasons.TGT_DRIVEN_PICKUP_DATE_OVERRIDE.label

      return {
        booking_id: bookingId,
        booking_version: bookingVersion,
        modified_attributes: {
          origin_location: {
            code: origLocCode,
            vop,
          },
          truck_load_optimization_ref_id: tloRefId || null, // temporary fix until backend handles omitting this
          commodity_code: commodityCode,
          measures: {
            carton_quantity: carton,
            pallet: pallet,
            weight: {
              total: totalWt,
              unit_of_measure: wtUom,
            },
            volume: {
              total: totalVol,
              unit_of_measure: volUom,
            },
          },
          ...(hasPickupOverride && {
            pickup_override: {
              date: dayjs(pickupDate).format('YYYY-MM-DD'),
              change_reason: pickupReasonCode,
            },
          }),
          vendor_release: getVendorRelease(vendorRelease, isModified),
          appointment_number: appointmentNumber,
          bill_of_lading: bol,
        },
      }
    }),
  }
}

export const shipmentsModificationEndpoint = async ({
  shipments,
  uid,
}: ShipmentModificationVariables) => {
  const modifiedShipments = shipments.filter((shipment: any) => {
    return !shipment?.toBeCancelled && !shipment?.loadId
  })
  const transformedShipments =
    transformShipmentsToModificationRequests(modifiedShipments)

  if (uid <= 0) {
    return
  }
  if (modifiedShipments.length <= 0) {
    return
  }
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/shipment_modifications?key=${key}`
  try {
    const response = await axios<ShipmentModificationVariables>({
      method: 'post',
      url: endpoint,
      data: transformedShipments,
    })

    const failures = get(response, 'data.failures', []).map((failure: any) => {
      return failure.booking_id
    })
    return shipments.reduce((currMods: any, shipment: any) => {
      if (failures.includes(shipment.bookingId)) {
        currMods.push(shipment)
      }
      return currMods
    }, [])
  } catch (err: any) {
    return modifiedShipments
  }
}
