import axios from 'axios'
import { getUiServicesEndpoint } from './utils'

interface MaxCapacityApiResponse {
  vendor_id: number
  location: {
    id: string
    max_outbound_trailers: number
  }
  pickup_date: string
  number_of_scheduled_loads: number
  is_location_over_capacity?: boolean
}

export const getLocationCapacityEndpoint = async (
  locationId: string,
  vendorId: number,
  pickupDate: string,
) => {
  const { key, url } = await getUiServicesEndpoint()
  return `${url}/v1/locations/capacity?vmm_location_id=${locationId}&vendor_id=${vendorId}&pickup_date=${pickupDate}&key=${key}`
}

export const fetchLocationCapacity = async (
  locationId: string | undefined,
  vendorId: number | undefined,
  pickupDate: string | undefined,
) => {
  if (
    locationId !== undefined &&
    vendorId !== undefined &&
    pickupDate !== undefined
  ) {
    return axios<MaxCapacityApiResponse>({
      method: 'get',
      url: await getLocationCapacityEndpoint(locationId, vendorId, pickupDate),
    }).then((res) => {
      return res.data
    })
  }
}
