import { getUiServicesEndpoint } from './utils'
import axios from 'axios'
import dayjs from 'dayjs'

export interface ConsolidatorShipmentForm {
  vendor_id: number
  origin_code: string
  dest_loc_code: string
  carton_qty?: number
  pallet?: number
  booking_weight?: {
    total_weight: number
    weight_uom: 'pound'
  } | null
  booking_volume?: {
    total_volume: number
    volume_uom: 'pound'
  } | null
  pickup_date: string
  bol?: string
  appointmentNumber?: string
}

interface ConsolidatorShipmentRequest {
  vendor_id: number
  origin_code: string
  destination_code: string
  carton_qty?: number | null
  pallet?: number | null
  booking_weight?: {
    total_weight: number
    weight_uom: 'pound'
  } | null
  booking_volume?: {
    total_volume: number
    volume_uom: 'pound'
  } | null
  user_provided_pickup_date: string
  bol?: string | null
  appt_num?: string | null
  vendor_type: VendorType
}

enum VendorType {
  CONSOLIDATOR = 'CONSOLIDATOR',
}

/**
 * Transforms formInputs into edge values expected by api
 * @param formInputs
 */
const transformShipmentBody = (
  formInputs: ConsolidatorShipmentForm,
): ConsolidatorShipmentRequest => {
  return {
    vendor_id: formInputs.vendor_id,
    origin_code: formInputs.origin_code,
    destination_code: formInputs.dest_loc_code,
    user_provided_pickup_date: dayjs(formInputs.pickup_date).format(
      'YYYY-MM-DD',
    ),
    bol: formInputs.bol || null,
    appt_num: formInputs.appointmentNumber || null,
    vendor_type: VendorType.CONSOLIDATOR,
  }
}

export const postNewConsolidatorShipmentDataEndpoint = async (
  createdShipment: ConsolidatorShipmentForm,
) => {
  try {
    const { key, url } = await getUiServicesEndpoint()
    const endpoint = `${url}/v1/non_po_shipment?key=${key}`

    const body = transformShipmentBody(createdShipment)
    const response = await axios.post(endpoint, body)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
