import axios from 'axios'
import { get } from 'lodash'
import { getUiServicesEndpoint } from './utils'
import {
  ShipmentModificationVariables,
  ShipmentRowInterface,
} from '../common/types'

export const transformShipments = (shipments: Array<ShipmentRowInterface>) => {
  const cancelledShipments = shipments.map((shipment) => {
    const { bookingId, bookingVersion } = shipment
    return {
      booking_id: bookingId,
      booking_version: bookingVersion,
      change_reason: 'SHIPMENT_NOT_NEEDED',
    }
  })

  return {
    cancellation_requests: cancelledShipments,
  }
}

export const shipmentsCancellationsEndpoint = async ({
  shipments,
  uid,
}: ShipmentModificationVariables) => {
  const shipmentsToCancel = shipments.filter((shipment: any) => {
    return shipment?.toBeCancelled && !shipment?.loadId
  })
  const transformedShipments = transformShipments(shipmentsToCancel)
  if (uid <= 0) {
    return
  }
  if (transformedShipments?.cancellation_requests?.length <= 0) {
    return
  }
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/shipment_cancellations?key=${key}`
  try {
    const response = await axios<ShipmentModificationVariables>({
      method: 'post',
      url: endpoint,
      data: transformedShipments,
    })
    const failureResults = get(response, 'data.failures', [])
    const failures = failureResults.map((failure: any) => {
      return failure?.booking_id
    })

    return shipments.reduce((currMods: any, shipment: any) => {
      if (failures.includes(shipment.bookingId)) {
        currMods.push(shipment)
      }
      return currMods
    }, [])
  } catch (err: any) {
    return shipmentsToCancel
  }
}
