import { Button } from '@enterprise-ui/canvas-ui-react'
import { PurchaseOrderInterface, ShipmentRowInterface } from 'common/types'
import { useGlobalContext } from 'context/GlobalContext'
import { useShipmentManagementContext } from 'context/ShipmentManagementContext'
import SubmitChanges from 'components/Modal/submitChanges'
import { shipmentsModified } from 'components/Shipments/utils'

export const ModifyShipments = () => {
  const { globalDispatch } = useGlobalContext()
  const { shipmentManagementState } = useShipmentManagementContext()
  const purchaseOrders: Array<PurchaseOrderInterface> = Object.values(
    shipmentManagementState.shipmentsByPurchaseOrderKey,
  )

  const shipmentsFromShipmentView =
    Object.values(shipmentManagementState.shipmentsViewData) || []

  const shipmentsFromPurchaseOrders = purchaseOrders.reduce(
    (prev: Array<any>, curr: any) => {
      return [...prev, ...Object.values(curr)]
    },
    [],
  )

  const filteredShipmentsModifiedReviewed = [
    ...shipmentsFromPurchaseOrders.filter(shipmentsModified),
    ...shipmentsFromShipmentView.filter(shipmentsModified),
  ]

  const invalidShipmentsModifiedReviewed = shipmentsFromPurchaseOrders.filter(
    (shipment: ShipmentRowInterface) =>
      !shipment.toBeCancelled && shipment.hasInvalidDimensions,
  )

  const hasPickupDateValidationErrors = shipmentsFromPurchaseOrders.filter(
    (shipment: ShipmentRowInterface) => {
      return (
        !shipment.toBeCancelled &&
        shipment.pickupDate !== shipment.original_pickupDate &&
        !shipment.pickupReasonCode
      )
    },
  )

  const hasShipmentsToUpdate =
    hasPickupDateValidationErrors.length <= 0 &&
    invalidShipmentsModifiedReviewed.length <= 0 &&
    filteredShipmentsModifiedReviewed.length > 0

  return (
    <Button
      className="hc-ma-normal"
      data-testid="button-shipment-checkout"
      disabled={!hasShipmentsToUpdate}
      type="primary"
      onClick={() => {
        globalDispatch({
          type: 'SET_MODAL',
          payload: {
            template: SubmitChanges,
            size: 'expanded',
          },
        })
      }}
    >
      Review and Submit({filteredShipmentsModifiedReviewed.length})
    </Button>
  )
}
