// @ts-nocheck
import { AuthProvider, ProtectedElement } from '@praxis/component-auth'
import { AnalyticsProvider } from '@praxis/component-analytics'
import { GlobalProvider } from 'context/GlobalContext'
import { EnvProvider } from '@praxis/component-runtime-env'
import { ErrorBoundary } from '@praxis/component-logging'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { getParam } from 'common/utils'
import apiConfig from 'config/apiConfig'
import { GlobalStyle } from './common/globalStyles'
import OfflineDialog from './components/OfflineDialog'
import { Main } from './components/Main/Main'
import { Unauthorized } from './components/Unauthorized/Unauthorized'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { JoyrideProvider } from './context/JoyrideContext'
import { UpdatesNotification } from 'UpdatesNotification'
import { UpdatesAvailableProvider } from 'UpdatesAvailableContext'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

const queryClient = new QueryClient({})

const App = () => {
  const isDebugMode = getParam('debug')

  return (
    <ErrorBoundary>
      <EnvProvider configPath="/config.json">
        <AuthProvider
          clientId={apiConfig.auth?.clientId}
          authorizationUrl={apiConfig.auth?.authorizationUrl}
          logoutUrl={apiConfig.auth?.logoutUrl}
        >
          <AnalyticsProvider eventManagerConfiguration={apiConfig.analytics}>
            <UpdatesAvailableProvider>
              <GlobalStyle />
              <GlobalProvider>
                <JoyrideProvider>
                  <ToastProvider>
                    <QueryClientProvider client={queryClient}>
                      <ProtectedElement
                        allowed={[
                          ...apiConfig.entitlements.polRead,
                          ...apiConfig.entitlements.tgtInternalRead,
                        ]}
                        renderUnauthorized={() => <Unauthorized />}
                      >
                        <Main />
                      </ProtectedElement>

                      {isDebugMode && (
                        <ReactQueryDevtools initialIsOpen={false} />
                      )}
                    </QueryClientProvider>
                  </ToastProvider>
                </JoyrideProvider>
              </GlobalProvider>
              <UpdatesNotification />
            </UpdatesAvailableProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </EnvProvider>
      <OfflineDialog />
    </ErrorBoundary>
  )
}
export default App
