import React from 'react'
import { fetchReducer, INITIAL_FETCH_STATE } from '../common/reducers'
import { useGlobalContext } from 'context/GlobalContext'
import { mapRequestVariables, createMappedShipmentsRequest } from './utils'
import { useShipmentManagementContext } from 'context/ShipmentManagementContext'
import { isEmpty } from 'lodash'
import { useToaster } from '@enterprise-ui/canvas-ui-react'

interface SettingsInterface {
  uid: number
  checkVersion: boolean
  hasRequiredEntitlements: boolean
  failedModifiedShipments?: Array<Object>
}

const useSyncShipmentsByPOs = (
  shipments: Array<any>,
  settings: SettingsInterface,
) => {
  const makeToast = useToaster()
  const { globalState } = useGlobalContext()
  const { shipmentManagementDispatch } = useShipmentManagementContext()

  const isInternal = globalState.userInfo.isInternal

  const [fetchState, fetchDispatch] = React.useReducer(
    fetchReducer,
    INITIAL_FETCH_STATE,
  )

  React.useEffect(() => {
    if (fetchState.message) {
      makeToast({
        message: `${fetchState.message} ${fetchState.isSuccess}`,
        type: 'error',
      })
    }
    // eslint-disable-next-line
  }, [fetchState.isError])

  React.useEffect(() => {
    if (!fetchState.isSuccess || isEmpty(fetchState.data)) {
      return
    }
    shipmentManagementDispatch({
      type: 'SYNC_SHIPMENTS_BY_PURCHASE_ORDER_KEY',
      payload: {
        data: fetchState.data,
        checkVersion: settings.checkVersion,
        hasRequiredEntitlements: settings.hasRequiredEntitlements,
        failedModifiedShipments: settings.failedModifiedShipments,
        isInternal,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchState.isSuccess])

  const fetchData = async () => {
    const filters = globalState.filters.shipmentManagement

    fetchDispatch({
      type: 'FETCH_INIT',
    })
    const mappedShipmentsRequest = createMappedShipmentsRequest(
      mapRequestVariables(shipments),
      filters,
    )

    Promise.allSettled(mappedShipmentsRequest)
      .then((response) => {
        fetchDispatch({
          type: 'FETCH_SUCCESS',
          payload: {
            response,
            message: 'Shipments Synced',
          },
        })
      })
      .catch((err: any) => {
        fetchDispatch({
          type: 'FETCH_FAILURE',
          payload: {
            error: err?.response?.data,
            message: 'Failed to Sync Shipments',
          },
        })
      })
  }

  React.useEffect(() => {
    if (settings.uid <= 0) {
      return
    }

    fetchData()
    // eslint-disable-next-line
  }, [settings.uid])

  return {
    syncShipmentsByPOsState: fetchState,
  }
}

export default useSyncShipmentsByPOs
