import FlexBox from 'components/FlexBox'
import { ModifyShipments } from './modifyShipments'
import { BottomActionsBar } from 'components/BottomActionsBar/BottomActionBar'

const ShipmentsActions = (_props: any) => {
  return (
    <BottomActionsBar>
      <FlexBox justify="flex-end" className="hc-pr-xs">
        <ModifyShipments />
      </FlexBox>
    </BottomActionsBar>
  )
}

export default ShipmentsActions
