import styled from 'styled-components'

const Bar = styled.div`
  background: #222;
  bottom: 0;
  color: white;
  font-size: 10px;
  left: 0;
  padding: 3px 6px;
  position: fixed;
  top: auto;
`

export const VersionBar = () => {
  return (
    <Bar>
      <span data-testid="version-number">v{process.env.APP_VERSION}</span>
    </Bar>
  )
}
