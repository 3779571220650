import axios from 'axios'
import { getMappedValue, getUiServicesEndpoint } from './utils'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { isNumber } from 'lodash'
import { LocationTransportationAttributesRequest } from '../common/types'

export const patchLocationEndpoint = async (
  updateBodyRequest: LocationTransportationAttributesRequest,
) => {
  const {
    vendorId,
    locationId,
    maxOutboundCartonsPerDay,
    maxOutboundTrailersPerDay,
    dropTrailerSchedule,
    acceptDropTrailers,
    dropTrailerRejectionReason,
    acceptRearRollDoorTrailers,
    rollDoorRejectionReason,
    verifiedByUser,
  } = updateBodyRequest
  const { key, url } = await getUiServicesEndpoint()
  const body = {
    max_outbound_cartons: getMappedValue(
      'maxOutboundCartonsPerDay',
      maxOutboundCartonsPerDay,
    ),
    max_outbound_trailers: getMappedValue(
      'maxOutboundTrailersPerDay',
      maxOutboundTrailersPerDay,
    ),
    accept_drop_trailers: getMappedValue(
      'acceptDropTrailers',
      acceptDropTrailers,
    ),
    drop_trailer_rejection_reason: getMappedValue(
      'dropTrailerRejectionReason',
      dropTrailerRejectionReason,
      acceptDropTrailers,
    ),
    drop_trailer_schedule: dropTrailerSchedule,
    accept_rear_roll_door_trailers: getMappedValue(
      'acceptRearRollDoorTrailers',
      acceptRearRollDoorTrailers,
    ),
    roll_door_rejection_reason: getMappedValue(
      'rollDoorRejectionReason',
      rollDoorRejectionReason,
      acceptRearRollDoorTrailers,
    ),
    verified_by_user: getMappedValue('verifiedByUser', verifiedByUser),
  }

  const foundUndefinedValues = Object.values(body).filter((value) => {
    return value === undefined
  })

  const foundNegativeNumberValues = Object.values(body).filter((value) => {
    return isNumber(value) && value < 0
  })
  if (foundUndefinedValues.length > 0) {
    throw Error('MISSING_VALUES')
  }

  if (foundNegativeNumberValues.length > 0) {
    throw Error('NEGATIVE_NUMBER_VALUES')
  }
  const endpoint = `${url}/v1/location/${locationId}/vendor_id/${vendorId}?key=${key}`

  try {
    const response = await axios.put(endpoint, body)
    return {
      response,
      locationId: locationId,
      message: `Location updated`,
    }
  } catch (err: any) {
    const message = 'FAILED_PATCH'

    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw Error(message)
  }
}
