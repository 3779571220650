import React from 'react'
import get from 'lodash/get'
import Cookies from 'js-cookie'
import { useAuth } from '@praxis/component-auth'
import { useGlobalContext } from 'context/GlobalContext'
import { SessionModalTemplate } from 'components/Modal/session'
import { poll } from 'common/utils'
import { LogLevel, logEvent } from '@praxis/component-logging'
import apiConfig from 'config/apiConfig'

const Session = () => {
  const auth = useAuth()
  const { session } = auth
  const { globalDispatch, globalState } = useGlobalContext()
  const {
    userInfo: { isInternal, isExternal, isSet },
  } = globalState

  const isPOL = window.location.host.includes('partnersonline')

  const doInvalidSession = (response: any) => {
    const { isNewUserId, isSsoLoggedOut, hasPOLId } = response
    if (isSsoLoggedOut || isNewUserId || !isExternal || !hasPOLId) {
      globalDispatch({
        type: 'SET_MODAL',
        payload: {
          template: SessionModalTemplate,
          props: {
            disableBackdropClose: true,
            hasPOLId,
            isExternal,
            isNewUserId,
            isSsoLoggedOut,
            tgtInternalUrl: apiConfig.api.edge,
          },
        },
      })
    }
  }

  const validateCookie = () => {
    const ssoCookie = Cookies.get('SSOSESSIONINFO') || ''
    const decodedSsoCookie = JSON.parse(atob(ssoCookie) || '{}')
    const userIDfromPOL = decodedSsoCookie.user
    const isSsoLoggedOut = !decodedSsoCookie.login_status
    const userIdFromTgt = get(session, 'userInfo.lanId', '')
    const isNewUserId = userIDfromPOL !== userIdFromTgt
    const hasPOLId = !!userIDfromPOL

    return {
      isSsoLoggedOut,
      isNewUserId,
      hasPOLId,
      userIdFromTgt,
      userIDfromPOL,
    }
  }

  React.useEffect(() => {
    if (!isSet || !isPOL || isInternal) {
      return
    }

    poll({
      fn: validateCookie,
      validate: (response: any) => {
        const { isSsoLoggedOut, isNewUserId } = response

        return isSsoLoggedOut || isNewUserId || !isExternal || isInternal
      },
      interval: 4000,
      maxAttempts: 9999999999999,
    }).then((response: any) => {
      const {
        isNewUserId,
        isSsoLoggedOut,
        hasPOLId,
        userIdFromTgt,
        userIDfromPOL,
      } = response

      logEvent(
        {
          message: JSON.stringify({
            userIdFromTgt,
            userIDfromPOL,
            isNewUserId,
            isSsoLoggedOut,
            hasPOLId,
            type: 'INVALID_COOKIE',
          }),
          url: window.location.href,
        },
        { level: LogLevel.Error },
      )

      doInvalidSession(response)
    })

    // eslint-disable-next-line
  }, [isSet])

  return <></>
}

export default Session
