import axios from 'axios'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { getUiServicesEndpoint } from './utils'
import {
  PurchaseOrderType,
  RawShipmentDetails,
  VendorIdType,
} from 'common/types'
import { isEmpty } from 'validate.js'

export const getShipmentsByFilters: (
  po?: PurchaseOrderType,
  department?: string,
  vendorIds?: VendorIdType[],
  shipmentIds?: Array<string>,
  loadIds?: Array<string>,
  truckLoadOptimizationRefIds?: Array<string>,
  dateFrom?: string,
  dateTo?: string,
  bookingCategories?: Array<string>,
  destinationLocCode?: Array<string>,
  originLocCodes?: Array<string>,
  currentPage?: number,
  itemsPerPage?: number,
) => Promise<Record<string, any>> = async (
  po,
  department,
  vendorIds,
  shipmentIds,
  loadIds,
  truckLoadOptimizationRefIds,
  dateFrom,
  dateTo,
  bookingCategories,
  destinationLocCode,
  originLocCodes,
  currentPage,
  itemsPerPage,
) => {
  const { key, url } = await getUiServicesEndpoint()
  let endpoint = `${url}/v1/search/shipments?key=${key}`
  if (!isEmpty(vendorIds)) {
    endpoint += `&vendor_ids=${vendorIds}`
  }
  if (!isEmpty(po)) {
    endpoint += `&po_numbers=${[po]}`
  }
  if (!isEmpty(department)) {
    endpoint += `&departments=${department}`
  }
  if (!isEmpty(shipmentIds)) {
    endpoint += `&tgt_booking_ids=${shipmentIds}`
  }

  if (!isEmpty(loadIds)) {
    endpoint += `&load_ids=${loadIds}`
  }

  if (!isEmpty(dateFrom)) {
    endpoint += `&date_from=${dateFrom}&date_to=${dateTo}`
  }
  if (!isEmpty(bookingCategories)) {
    endpoint += `&booking_categories=${bookingCategories}`
  }
  if (!isEmpty(destinationLocCode)) {
    endpoint += `&destination_location_codes=${destinationLocCode}`
  }
  if (!isEmpty(truckLoadOptimizationRefIds)) {
    endpoint += `&tlo_ref_ids=${truckLoadOptimizationRefIds}`
  }
  if (!isEmpty(originLocCodes)) {
    endpoint += `&origin_location_codes=${originLocCodes}`
  }
  if (!isEmpty(currentPage)) {
    endpoint += `&page=${currentPage}`
  }
  if (!isEmpty(itemsPerPage)) {
    endpoint += `&per_page=${itemsPerPage}`
  }

  try {
    const response = await axios<{
      list: RawShipmentDetails[]
      pagination_metadata: Record<string, any>
    }>({
      method: 'get',
      url: endpoint,
    })
    return response.data ?? {}
  } catch (err: any) {
    const message = `Failed to load Shipments for Purchase Order ${po}`

    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw new Error(message)
  }
}
