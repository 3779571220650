import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { Department } from 'common/types'
export type DepartmentsApiResponse = {
  data: { departments: Department[] }
}

export const departmentsEndpoint: (
  vendorIds?: number[],
) => Promise<string> = async (vendorIds?: number[]) => {
  const { key, url } = await getUiServicesEndpoint()
  return vendorIds && vendorIds?.length
    ? `${url}/v1/departments?key=${key}&vendor_ids=${vendorIds.join(',')}`
    : `${url}/v1/departments?key=${key}`
}

export const getDepartments: (
  vendorIds?: number[],
) => Promise<Department[]> = async (vendorIds) => {
  const endpoint = await departmentsEndpoint(vendorIds)
  try {
    const response: DepartmentsApiResponse = await axios.get(endpoint)
    return response?.data?.departments || []
  } catch (err: any) {
    throw new Error('Failed to fetch departments')
  }
}
