import { Grid, Card } from '@enterprise-ui/canvas-ui-react'

export const Welcome = (props: { message: string }) => {
  return (
    <Grid.Container
      className="hc-mt-4x hc-ta-center hc-mb-4x"
      id="welcome-to-shipiq"
    >
      <Grid.Item xs>
        <Card elevation={0} className="hc-fs-xl hc-mb-normal hc-clr-grey01">
          <p className="hc-fs-xl hc-mb-normal hc-clr-grey01">
            Welcome to ShipIQ!
          </p>
          <p className="hc-fs-sm">{props.message}</p>
        </Card>
      </Grid.Item>
    </Grid.Container>
  )
}
