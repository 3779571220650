import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Button,
  Grid,
  Heading,
  Divider,
  Input,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '@praxis/component-auth'
import { useAnalyticsWrapper } from '../../hooks/useAnalyticsWrapper'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import {
  getUserPreferences,
  updateUserPreferences,
} from 'services/notifications'
import { UpdateUserNotificationPreferencesRequest } from '../../common/types'

interface ProfileDrawerProps {
  isOpen: boolean
  onClose: () => void
}

const ProfileDrawer = ({ isOpen, onClose }: ProfileDrawerProps) => {
  const queryClient = useQueryClient()
  const makeToast = useToaster()
  const auth = useAuth()
  const analyticsWrapper = useAnalyticsWrapper()
  const { logout, session } = auth

  const userId = localStorage.getItem('userInfo')?.split(',')[0] || 'N/A'
  const { data: userNotificationPreferences, isLoading: isPreferencesLoading } =
    useQuery(['userNotificationPreferencesQueryKey', userId], () =>
      getUserPreferences(userId),
    )

  const [isSubscribed, setIsSubscribed] = useState(false)

  useEffect(() => {
    if (
      userNotificationPreferences?.subscribedToEmailNotifications !== undefined
    ) {
      setIsSubscribed(
        userNotificationPreferences.subscribedToEmailNotifications,
      )
    }
  }, [userNotificationPreferences])

  const handleSubscribeToggle = async () => {
    const request: UpdateUserNotificationPreferencesRequest = {
      subscribedToEmailNotifications:
        !userNotificationPreferences?.subscribedToEmailNotifications,
    }
    try {
      await updateUserPreferences(request, userId)
      await queryClient.invalidateQueries('userNotificationPreferencesQueryKey')
      makeToast({
        message: `Successfully updated preferences`,
        type: 'success',
      })
    } catch (error) {
      makeToast({
        message: `Unable to update preferences, please try again later`,
        type: 'error',
      })
      console.error('Error updating preferences:', error)
    }
  }

  return (
    <Drawer
      data-testid="profile-drawer"
      location="right"
      isVisible={isOpen}
      onRequestClose={onClose}
      headingText="Your Profile"
    >
      {isPreferencesLoading ? (
        <Spinner size="inline" />
      ) : (
        <>
          <Grid.Container justify="space-between">
            <Grid.Item>
              <div className="pa-normal">
                <p className="hc-fs-md">{session?.userInfo?.email}</p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  analyticsWrapper?.trackTaskDone({
                    name: 'ShipIQ logout',
                    status: 'success',
                  })
                  logout()
                }}
                variant="outlined"
                data-testid="logout-button"
              >
                Logout
              </Button>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container align="flex-end" className="hc-mt-expanded">
            <Grid.Item>
              <div className="pa-normal">
                <Heading size={4}>Preferences</Heading>
                <Divider className="hc-mv-normal" />
                <p className="hc-fs-sm">
                  You are currently{' '}
                  <strong>
                    {isSubscribed ? 'subscribed' : 'unsubscribed'}
                  </strong>{' '}
                  to email notifications, click to{' '}
                  {isSubscribed ? 'unsubscribe' : 'subscribe'}
                </p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <Input.Toggle
                defaultValue={isSubscribed}
                data-testid="email-notifications-subscription-toggle"
                value={isSubscribed}
                onChange={handleSubscribeToggle}
              />
            </Grid.Item>
          </Grid.Container>
        </>
      )}
    </Drawer>
  )
}

export default ProfileDrawer
