import { Heading, Card } from '@enterprise-ui/canvas-ui-react'

export interface SessionInterface {
  hasPOLId: boolean
  isExternal: string
  isNewUserId: string
  isSsoLoggedOut: boolean
  tgtInternalUrl: string
}

export const SessionModalTemplate = (props: SessionInterface) => {
  const { hasPOLId, isExternal, isNewUserId, isSsoLoggedOut, tgtInternalUrl } =
    props

  if (isSsoLoggedOut || isNewUserId || !isExternal) {
    setTimeout(() => {
      localStorage.clear()
      window.location.reload()
    }, 4000)
  }

  return (
    <Card
      className="hc-mt-4x hc-pa-sm hc-pb-lg hc-ta-center"
      style={{
        width: 320,
      }}
    >
      <Heading size={6} className="hc-fs-lg">
        This Session Has Ended
      </Heading>
      <br />
      <div className="hc-fs-md">
        {!isExternal &&
          hasPOLId &&
          'This Business Partner Id is not permissioned to use ShipIQ. '}
        {!hasPOLId && (
          <div>
            If you are a Target Team member please login using the following
            link <a href={tgtInternalUrl}>{tgtInternalUrl}</a>
          </div>
        )}
        {isSsoLoggedOut &&
          isExternal &&
          'If you would like to continue your session, please wait while we redirect you to the authentication page to login again.'}
        {!isSsoLoggedOut &&
          isNewUserId &&
          isExternal &&
          'Looks like you have switched companies in POL, We will refresh your session to match the company you have switched to.'}
      </div>
    </Card>
  )
}
