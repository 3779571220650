import ReactJoyride, { CallBackProps, EVENTS } from 'react-joyride'
import { useState } from 'react'
import { useWindowSize } from 'react-use'
import { useJoyrideContext } from '../../context/JoyrideContext'
import { featureAnnouncementSteps } from './JoyrideSteps/featureAnnouncementSteps'
import { welcomeSteps } from './JoyrideSteps/welcomeSteps'

// STEPS FOR JOYRIDE
const steps = [...welcomeSteps, ...featureAnnouncementSteps]

export const Joyride = () => {
  const { joyrideState, joyrideDispatch } = useJoyrideContext()
  const { height } = useWindowSize()
  const [previousAction, setPreviousAction] = useState<string | null>(null) // needed bc joyride sends component not found event after close event

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, type } = data

    if (action === 'close' || action === 'reset') {
      joyrideDispatch({ type: 'JOYRIDE_ALL' })
      setPreviousAction('close')
      return
    }

    if (type === EVENTS.TARGET_NOT_FOUND && previousAction !== 'close') {
      joyrideDispatch({ type: 'JOYRIDE_ALL' })

      setTimeout(() => {
        joyrideDispatch({ type: 'JOYRIDE_ALL' })
      }, 0)
    }
  }

  return (
    <ReactJoyride
      showProgress
      continuous
      disableOverlayClose
      run={joyrideState.showAll}
      styles={{
        options: {
          arrowColor: '#3d70d6',
          primaryColor: '#3d70d6',
        },
        tooltip: {
          maxHeight: `${height - 75}px`,
          overflowY: 'auto',
        },
      }}
      callback={handleJoyrideCallback}
      // @ts-ignore
      steps={steps}
    />
  )
}
