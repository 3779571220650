import {
  Anchor,
  Card,
  List,
  Grid,
  Heading,
  ActionArea,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CalendarIcon,
  ClockIcon,
  TruckIcon,
} from '@enterprise-ui/icons'
import { Notifications } from 'common/types'
import { getNotificationUrl } from './utils'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import { clearNotification } from '../../services/notifications'
import { useQueryClient } from 'react-query'
import { MouseEvent } from 'react'

export const NotificationsContent = (props: {
  vendorIds: number[]
  userManagedNotifications?: Notifications[]
  systemManagedNotifications?: Notifications[]
}) => {
  const queryClient = useQueryClient()
  const makeToast = useToaster()
  const { userManagedNotifications, systemManagedNotifications, vendorIds } =
    props
  const ICON_CLASSES: Record<string, string> = {
    NOTICE: 'hc-clr-success',
    INFO: 'hc-clr-interactive',
    URGENT: 'hc-clr-error',
  }

  const handleMarkAsReadButtonClick = async (
    event: MouseEvent,
    notificationIds: string[],
  ) => {
    event.stopPropagation()
    const userId = localStorage.getItem('userInfo')?.split(',')[0]

    try {
      await Promise.all(
        notificationIds.map((notificationId) =>
          clearNotification({ userId, notificationId }),
        ),
      )
      await queryClient.invalidateQueries('notificationsQueryKey')
      makeToast({
        message: `Cleared notifications`,
        type: 'success',
      })
    } catch (error) {
      makeToast({
        message: `Unable to clear notifications. Please try again later`,
        type: 'error',
      })
      console.error('Error clearing notifications:', error)
    }
  }

  const handleNotificationClick = (link: string) => {
    window.location.href = link
  }

  const pickupNotificationLabel: String = 'Pickup Date Assigned / Changed'

  const analyticsWrapper = useAnalyticsWrapper()

  const trackAnalytics = (type: string) => {
    analyticsWrapper?.trackTaskDone({
      name: `${type} Notifications link clicked`,
      status: 'success',
    })
  }
  return (
    <Card elevation={0}>
      <List>
        {!!systemManagedNotifications?.length &&
          systemManagedNotifications.map((notifications, idx) => {
            const link = getNotificationUrl(vendorIds, notifications)
            return (
              <ActionArea
                onClick={() => handleNotificationClick(link)}
                key={`system_notifications_${idx}`}
                data-testid={`system_notifications_${idx}`}
                target="_self"
                onMouseDown={() => trackAnalytics(notifications.entity)}
              >
                <Card className="hc-pa-normal hc-mb-xs" elevation={0}>
                  <Grid.Container
                    align="center"
                    className="hc-mb-xl"
                    data-testid={'system-managed-notifications-container'}
                  >
                    <Grid.Item xs={2}>
                      <EnterpriseIcon
                        size="xl"
                        icon={ClockIcon}
                        className={ICON_CLASSES[notifications.level]}
                      />
                    </Grid.Item>
                    <Grid.Item xs className={'hc-fs-sm'}>
                      <p>{notifications.label}</p>
                    </Grid.Item>
                    <Grid.Item>
                      <Heading size={4}>
                        {notifications.identifiers.length}
                      </Heading>
                    </Grid.Item>
                  </Grid.Container>
                </Card>
              </ActionArea>
            )
          })}
      </List>
      {!!userManagedNotifications?.length && (
        <>
          <hr className="hc-mt-lg hc-mb-lg" />
          <List>
            {userManagedNotifications!!.map((notifications, idx) => {
              const link = getNotificationUrl(vendorIds, notifications)
              return (
                <ActionArea
                  onClick={() => handleNotificationClick(link)}
                  target="_self"
                  onMouseDown={() => trackAnalytics(notifications.entity)}
                  key={`user_notifications_${idx}`}
                  data-testid={`user_notifications_${idx}`}
                >
                  <Card className="hc-pa-normal hc-mb-min" elevation={0}>
                    <Grid.Container
                      align="center"
                      data-testid={'user-managed-notifications-container'}
                    >
                      <Grid.Item xs={2}>
                        <EnterpriseIcon
                          size="xl"
                          icon={
                            notifications.label === pickupNotificationLabel
                              ? CalendarIcon
                              : TruckIcon
                          }
                          className={ICON_CLASSES[notifications.level]}
                        />
                      </Grid.Item>
                      <Grid.Item xs className={'hc-fs-sm'}>
                        <p>{notifications.label}</p>
                      </Grid.Item>
                      <Grid.Item>
                        <Heading size={4}>
                          {notifications.identifiers.length}
                        </Heading>
                      </Grid.Item>
                      <Grid.Item xs={12} className="hc-ta-right">
                        {notifications.identifiers.length > 0 && (
                          <Anchor
                            onClick={(event: MouseEvent) =>
                              handleMarkAsReadButtonClick(
                                event,
                                notifications.identifiers.map(
                                  (identifier) =>
                                    identifier.notification_id ?? '',
                                ),
                              )
                            }
                          >
                            Mark as read
                          </Anchor>
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </Card>
                </ActionArea>
              )
            })}
          </List>
        </>
      )}
    </Card>
  )
}
