import { Suspense, lazy, useEffect, useState } from 'react'
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom'
import './animate.css'
import Logo from '../../media/shipiq.svg'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { ShipmentProvider } from 'context/ShipmentManagementContext'
import { TransportationProfileProvider } from 'context/TransportationProfileContext'
import { useGlobalContext } from 'context/GlobalContext'
import { useJoyrideContext } from '../../context/JoyrideContext'
import Session from 'components/Session'
import { VersionBar } from 'components/VersionBar/VersionBar'
import PageNotFound from 'components/Routes/PageNotFound'
import {
  fetchVendors,
  transformVendors,
  vendorsEndpoint,
} from 'services/getVendors'

import {
  fetchBasicLocationsAndVendors,
  basicLocationTransform,
  basicLocationsEndpoint,
} from 'services/getBasicLocationsVendors'
import Modal from 'components/Modal'
import Queries from 'components/Queries'
import {
  SHIPMENT_MANAGEMENT_TABS,
  getUserInfo,
  isNewFeatureVersion,
} from './utils'
import {
  getParam,
  getShipmentManagementFiltersFromUrl,
  mfeRoutes,
} from 'common/utils'
import mockVendor from 'mocks/mockVendors.json'
import Axios from 'axios'
import { LogLevel, logEvent } from '@praxis/component-logging'
import { useQuery } from 'react-query'
import {
  Grid,
  Layout,
  ProgressBar,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { FAQ } from '../FAQ/FAQ'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import styled from 'styled-components'
import EnterpriseIcon, { CloudCheckIcon } from '@enterprise-ui/icons'
import ProfileMenu from 'components/Main/ProfileMenu'
import { useUpdatesAvailable } from 'UpdatesAvailableContext'
import { VIEWS } from './constants'
import { SideNavBar } from './SideNavBar'
import { LocationHeader } from './LocationHeader'
import { MicroFrontend } from '@praxis/component-microfrontend'
import { LayoutSimple } from 'views/LayoutSimple'
import Cookies from 'js-cookie'
import { UpdateNotificationUserRequest } from '../../common/types'
import { putNotificationUser } from '../../services/notifications'

const ShipmentManagement = lazy(
  () => import('components/Routes/ShipmentManagement'),
)
const TransportationProfile = lazy(
  () => import('components/TransportationProfile'),
)

const StyledProgressBar = styled(ProgressBar)`
  height: 6px !important;
`

export const Main = () => {
  const { isUpdateAvailable, isDeferred, updateAssets } = useUpdatesAvailable()
  const showUpdatesAvailable = isUpdateAvailable || isDeferred
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)

  const auth = useAuth()
  const analyticsWrapper = useAnalyticsWrapper()
  const { isAuthorized, login, session } = auth
  const isAuthenticated = isAuthorized()
  const env = useEnv()
  const { globalState, globalDispatch } = useGlobalContext()
  const { isLoading, userInfo } = globalState
  const { joyrideDispatch } = useJoyrideContext()
  const { isExternal, isInternal, isSet } = userInfo

  useEffect(() => {
    analyticsWrapper?.trackTaskInit({ name: 'Login successful' })
    analyticsWrapper?.trackCustomEvent(
      'login',
      'user',
      JSON.stringify(localStorage.getItem('userInfo')),
      'success',
    )
  }, [analyticsWrapper])

  const vendorQuery = useQuery({
    queryKey: ['vendors'],
    queryFn: fetchVendors,
    select: transformVendors,
    refetchOnWindowFocus: false, // default: true
    onSuccess(data) {
      if (!isSet) {
        return
      }

      if (isInternal && data) {
        globalDispatch({
          type: 'SET_VENDORS',
          payload: data,
        })
        return
      }

      if (getParam('mockVendor') === true) {
        globalDispatch({
          type: 'SET_VENDORS',
          payload: mockVendor,
        })

        globalDispatch({
          type: 'SET_FILTERS',
          payload: {
            shipmentManagement: getShipmentManagementFiltersFromUrl(
              mockVendor.vendors,
            ),
          },
        })
      }

      if (isExternal && data) {
        globalDispatch({
          type: 'SET_VENDORS',
          payload: data,
        })
        globalDispatch({
          type: 'SET_FILTERS',
          payload: {
            shipmentManagement: getShipmentManagementFiltersFromUrl(
              data.vendors,
            ),
          },
        })
      }
    },
  })

  const basicLocationsQuery = useQuery({
    queryKey: ['basicLocations'],
    queryFn: fetchBasicLocationsAndVendors,
    select: basicLocationTransform,
    refetchOnWindowFocus: false, // default: true
    onSuccess(data) {
      if (!isSet) {
        return
      }
      if (data) {
        globalDispatch({
          type: 'SET_BASIC_LOCS_VENDORS',
          payload: data,
        })
        return
      }
    },
  })

  const makeCallToUpdateUserInfo = () => {
    const createNotificationUserRequest: UpdateNotificationUserRequest = {
      userId: localStorage.getItem('userInfo')?.split(',')[0],
      email: localStorage.getItem('userInfo')?.split(',')[1].trimStart(),
    }

    putNotificationUser(createNotificationUserRequest)
      .then(() => {
        Cookies.set('hasSuccessfullyCalledNotificationUserEndpoint', 'yes')
      })
      .catch(() => {
        Cookies.set('hasSuccessfullyCalledNotificationUserEndpoint', 'no')
      })
  }

  if (vendorQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: vendorsEndpoint,
          error: vendorQuery.error,
          message: 'Failed to fetch vendors',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }

  if (basicLocationsQuery.isError) {
    logEvent(
      {
        message: JSON.stringify({
          endpoint: basicLocationsEndpoint,
          error: vendorQuery.error,
          message: 'Failed to fetch basic locations vendors details',
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )
  }

  if (!isAuthenticated) {
    login()
  }

  // Once the user is Authenticated, set the ID-Token in the header
  Axios.interceptors.request.use(function (config: any) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'ID-Token': `Bearer ${localStorage?.id_token}`,
      },
    }
  })

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    globalDispatch({
      type: 'SET_USER_PERMISSIONS',
      payload: getUserInfo(session),
    })

    localStorage.setItem(
      'userInfo',
      `${session?.userInfo?.lanId}, ${session?.userInfo?.email}`,
    )

    localStorage.setItem('userId', `${session?.userInfo?.lanId}`)
    // eslint-disable-next-line
  }, [isAuthenticated])

  const notificationUserCookie: String =
    Cookies.get('hasSuccessfullyCalledNotificationUserEndpoint') || 'no'

  if (notificationUserCookie === 'no') {
    makeCallToUpdateUserInfo()
  }

  if (
    isAuthenticated &&
    isNewFeatureVersion(
      process.env.APP_VERSION,
      localStorage.getItem('prevVersion'),
    )
  ) {
    if (process.env.APP_VERSION) {
      localStorage.setItem('prevVersion', process.env.APP_VERSION)
    }
    joyrideDispatch({ type: 'JOYRIDE_ALL' })
  }

  return (
    <Router>
      <Layout darkMode={false} fullWidth>
        <SideNavBar
          isSideNavOpen={isSideNavOpen}
          setIsSideNavOpen={setIsSideNavOpen}
        />
        <Layout.Header
          onClick={() => setIsSideNavOpen(false)}
          includeRail
          className="hc-pt-none hc-pb-none hc-pl-4x hc-bg-grey07"
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item className="hc-pl-none">
              <Logo style={{ width: '85px' }} />
            </Grid.Item>
            <Grid.Item>
              <LocationHeader />
            </Grid.Item>
            {showUpdatesAvailable && (
              <Grid.Item>
                <Button
                  onClick={updateAssets}
                  type="primary"
                  className="hc-fs-sm hc-ws-no-wrap"
                >
                  <EnterpriseIcon
                    icon={CloudCheckIcon}
                    className="hc-mr-sm hc-pt-min"
                  />
                  Update ShipIQ App
                </Button>
              </Grid.Item>
            )}
            <Grid.Item className="hc-pr-none">
              <ProfileMenu />
            </Grid.Item>
          </Grid.Container>
        </Layout.Header>
        <Layout.Body
          includeRail
          className="hc-pa-none hc-bg-contrast-weak"
          onClick={() => setIsSideNavOpen(false)}
        >
          <div className="hc-pl-3x">
            <ShipmentProvider>
              <TransportationProfileProvider>
                <Queries />
                {/* <NavBar /> */}
                {isLoading && <StyledProgressBar indeterminate />}
                <div>
                  <Suspense fallback={<ProgressBar indeterminate />}>
                    <Routes>
                      <Route
                        path="/shipment-management"
                        element={<ShipmentManagement />}
                      />

                      <Route
                        path="/shipment-management/profile"
                        element={<ShipmentManagement />}
                      />

                      <Route
                        path="/shipment-management/purchase-orders"
                        element={
                          <ShipmentManagement
                            tab={SHIPMENT_MANAGEMENT_TABS.PURCHASE_ORDERS}
                          />
                        }
                      />

                      <Route
                        path="/lifecycle"
                        element={
                          <ShipmentManagement
                            tab={SHIPMENT_MANAGEMENT_TABS.PURCHASE_ORDERS}
                          />
                        }
                      />

                      <Route
                        path="/shipment-management/shipments"
                        element={
                          <ShipmentManagement
                            tab={SHIPMENT_MANAGEMENT_TABS.SHIPMENTS}
                          />
                        }
                      />

                      <Route
                        path="/transportation-profile/:vendorId/:tab"
                        element={<TransportationProfile />}
                      />

                      <Route
                        path="/transportation-profile/:vendorId"
                        element={<TransportationProfile />}
                      />

                      <Route
                        path="/transportation-profile"
                        element={<TransportationProfile />}
                      />

                      <Route path="/faq" element={<FAQ />} />

                      <Route
                        path="/"
                        element={
                          <Navigate
                            replace
                            to={VIEWS.SHIPMENT_MANAGEMENT.path}
                          />
                        }
                      />

                      {mfeRoutes(env).map((mfe: any) => {
                        return (
                          <Route
                            path={mfe.routePath}
                            element={
                              <LayoutSimple
                                component={
                                  <MicroFrontend
                                    src={mfe.host}
                                    prefix={mfe.prefix}
                                    hostUrl={mfe.hostUrl}
                                  />
                                }
                                name={mfe.name}
                              />
                            }
                            key={mfe.to}
                          />
                        )
                      })}

                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </Suspense>
                </div>

                <Session />
                <Modal />
                <VersionBar />
              </TransportationProfileProvider>
            </ShipmentProvider>
          </div>
        </Layout.Body>
      </Layout>
    </Router>
  )
}
