import { logEvent, LogLevel } from '@praxis/component-logging'

export const INITIAL_FETCH_STATE = {
  isComplete: false,
  isError: false,
  isLoading: false,
  isSuccess: false,
  data: null,
  message: null,
}

export const fetchReducer = (state: any, action: any) => {
  const { payload, type } = action
  switch (type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isComplete: false,
        isError: false,
        isLoading: true,
        isSuccess: false,
        data: null,
        message: null,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isComplete: true,
        isError: false,
        isLoading: false,
        isSuccess: true,
        data: action.payload.response,
        message: action.payload.message,
      }
    case 'FETCH_FAILURE':
      logEvent(
        {
          message: JSON.stringify({
            endpoint: payload?.endPoint,
            error: payload?.error,
            message: payload?.message,
            type: 'REQUEST_FAILURE',
            user: localStorage?.userInfo,
          }),
          url: window.location.href,
        },
        { level: LogLevel.Error },
      )
      return {
        ...state,
        isComplete: true,
        isError: true,
        isLoading: false,
        data: action.payload.response,
        message: action.payload.message,
      }
    case 'NO_FETCH':
      return {
        ...state,
        isComplete: true,
        isError: false,
        isLoading: false,
        data: null,
        message: null,
      }
    default:
      throw new Error()
  }
}
