import { getUiServicesEndpoint } from './utils'
import axios from 'axios'

export const sendToTmEndpoint = async (bookingIds: Array<number>) => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/shipments/send_to_tm?key=${key}`
  const response = await axios({
    method: 'post',
    url: endpoint,
    data: { booking_ids: bookingIds },
  })
  return response.data
}
