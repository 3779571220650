import { useState } from 'react'
import { SideNav, TargetLogo } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  BackroomLocationManagementIcon,
  HelpIcon,
  TruckIcon,
  ThumbsUpIcon,
} from '@enterprise-ui/icons'
import { VIEWS } from './constants'
import { useNavigate } from 'react-router-dom'
import Logo from '../../media/shipiq.svg'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import dayjs from 'dayjs'

export enum TABS {
  SHIPMENT_MANAGEMENT = 'shipment-management-tab',
  TRANPORTATION_PROFILE = 'transportation-profile-tab',
  FAQ = 'faq-tab',
  FEATURE_UPVOTE = 'feature-upvote-tab',
}

const getTabFromUrlPath = () => {
  if (window.location.href.includes('shipment-management')) {
    return TABS.SHIPMENT_MANAGEMENT
  }

  if (window.location.href.includes('transportation-profile')) {
    return TABS.TRANPORTATION_PROFILE
  }

  if (window.location.href.includes('faq')) {
    return TABS.FAQ
  }

  if (window.location.href.includes('featureupvote')) {
    return TABS.FEATURE_UPVOTE
  }
}

export const SideNavBar = ({
  isSideNavOpen,
  setIsSideNavOpen,
}: {
  isSideNavOpen: boolean
  setIsSideNavOpen: (isOpen: boolean) => void
}) => {
  const navigate = useNavigate()
  const [navBar, setNavbar] = useState(getTabFromUrlPath())
  const analyticsWrapper = useAnalyticsWrapper()
  const featureUpvoteLastViewedDate = localStorage.getItem(
    'lastFeatureUpvoteViewed',
  )
  const featureUpvoteUpdatedOnDate = dayjs(
    process.env.FEATURE_UPVOTE_LAST_UPDATED_DATE,
  )
  // has feature upvote app been updated since last viewed
  const isNewFeatureUpvotes =
    !featureUpvoteLastViewedDate ||
    dayjs(featureUpvoteLastViewedDate).isBefore(
      dayjs(featureUpvoteUpdatedOnDate),
      'day',
    )

  return (
    <SideNav
      canMinimize
      onMinimizeChange={(isMinimized: boolean | string) => {
        if (typeof isMinimized === 'boolean') {
          setIsSideNavOpen(!isMinimized)
        }
      }}
      isMinimized={!isSideNavOpen}
      hasOverlay={false}
      isVisible
    >
      <SideNav.Header
        aria-label="Side navigation header opens/minimizes side navigation"
        hideWhenMinimized={false}
        as="div"
        role="button"
        className="hc-pt-sm"
        onClick={() => setIsSideNavOpen(!isSideNavOpen)}
      >
        <TargetLogo size="expanded" className="hc-ma-none" />
      </SideNav.Header>
      <SideNav.Navigation>
        <SideNav.NavigationItem
          data-testid={TABS.SHIPMENT_MANAGEMENT}
          onClick={() => {
            setIsSideNavOpen(false)
            setNavbar(TABS.SHIPMENT_MANAGEMENT)
            navigate(VIEWS.SHIPMENT_MANAGEMENT.path)
          }}
          selected={navBar === TABS.SHIPMENT_MANAGEMENT}
        >
          Shipment Management
          <EnterpriseIcon icon={TruckIcon} />
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          data-testid={TABS.TRANPORTATION_PROFILE}
          onClick={() => {
            setIsSideNavOpen(false)
            setNavbar(TABS.TRANPORTATION_PROFILE)
            navigate(VIEWS.TRANSPORTATION_PROFILE.path)
          }}
          selected={navBar === TABS.TRANPORTATION_PROFILE}
        >
          Transportation Profile
          <EnterpriseIcon icon={BackroomLocationManagementIcon} />
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          data-testid={TABS.FAQ}
          onClick={() => {
            setIsSideNavOpen(false)
            setNavbar(TABS.FAQ)
            navigate(VIEWS.FAQ.path)
          }}
          selected={navBar === TABS.FAQ}
        >
          F.A.Q
          <EnterpriseIcon icon={HelpIcon} />
        </SideNav.NavigationItem>
        <SideNav.NavigationItem
          className={isNewFeatureUpvotes ? 'animate-wiggle' : ''}
          data-testid={TABS.FEATURE_UPVOTE}
          onClick={() => {
            setIsSideNavOpen(false)
            setNavbar(TABS.FEATURE_UPVOTE)
            localStorage.setItem(
              'lastFeatureUpvoteViewed',
              dayjs().format('YYYY-MM-DD'),
            )
            analyticsWrapper?.trackCustomEvent(
              'feature upvote',
              'userInfo',
              JSON.stringify(localStorage.getItem('userInfo')),
              'feature upvote clicked',
            )

            navigate(VIEWS.FEATURE_UPVOTE.path)
          }}
          selected={navBar === TABS.FEATURE_UPVOTE}
        >
          Feature Upvote
          <EnterpriseIcon icon={ThumbsUpIcon} />
        </SideNav.NavigationItem>
      </SideNav.Navigation>
      <SideNav.Footer className="hc-ta-center">
        <Logo style={{ width: '95px' }} />
      </SideNav.Footer>
    </SideNav>
  )
}
