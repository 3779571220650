/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import { get } from 'lodash'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { getUiServicesEndpoint } from './utils'
import { RawShipmentDetails } from 'common/types'

export const getShipmentsNeedReviewByVendorTloRefId: (
  vendorIds: number[],
  tloRefId: string,
) => Promise<RawShipmentDetails[]> = async (vendorIds, tloRefId) => {
  const { key, url } = await getUiServicesEndpoint()
  let endpoint = `${url}/v1/search/shipments?vendor_ids=${vendorIds}&tlo_ref_ids=${[
    tloRefId,
  ]}&booking_categories=CONTENT_NEEDS_REVIEW&key=${key}`
  try {
    const response = await axios({
      method: 'get',
      url: endpoint,
    })
    // TODO: replace with response?.data?.list || [] to remove lodash import - typescript issue to solve
    return get(response, 'data.list', [])
  } catch (err: any) {
    const message = `Failed to load Shipments for TLO reference ID ${tloRefId}`

    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw new Error(message)
  }
}
