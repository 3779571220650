import { createContext, useContext, useReducer } from 'react'
type JoyRideState =
  | Partial<{
      showAll: boolean
      search: Record<string, boolean>
      shipmentList: Record<string, boolean>
    }>
  | any
const initialState: JoyRideState = {
  showAll: false,
  search: { showAll: false, departmentFilter: false },
  shipmentList: { showAll: false, cancelShipmentButton: false },
}

type Action = {
  type:
    | 'JOYRIDE_ALL'
    | 'JOYRIDE_ALL_SEARCH'
    | 'JOYRIDE_DEPT_SEARCH'
    | 'JOYRIDE_ALL_SHIPMENT_LIST'
    | 'JOYRIDE_SHIPMENT_LIST_CANCEL_BUTTONS'
  payload: Record<string, boolean | Record<string, boolean> | undefined>
}

type JoyrideContextShape = {
  joyrideState: any
  joyrideDispatch: any
}

const JoyrideContext = createContext<JoyrideContextShape>({
  joyrideState: {},
  joyrideDispatch: {},
})

function joyrideReducer(state: JoyRideState, action: Action) {
  switch (action.type) {
    case 'JOYRIDE_ALL': {
      const value = action.payload?.value
      return { ...state, showAll: value ?? !state.showAll }
    }
    case 'JOYRIDE_ALL_SEARCH':
      return { ...state, search: { showAll: !state?.search?.showAll } }
    case 'JOYRIDE_DEPT_SEARCH':
      return {
        ...state,
        search: {
          ...state.search,
          departmentFilter: !state?.search?.departmentFilter,
        },
      }
    case 'JOYRIDE_ALL_SHIPMENT_LIST':
      return {
        ...state,
        shipmentList: { showAll: !state?.shipmentList?.showAll },
      }
    case 'JOYRIDE_SHIPMENT_LIST_CANCEL_BUTTONS':
      return {
        ...state,
        shipmentList: {
          ...state.shipmentList,
          cancelShipmentButton: !state?.shipmentList?.cancelShipmentButton,
        },
      }
    default:
      break
  }
}

export const JoyrideProvider = (props: any) => {
  const { children } = props
  const [state, dispatch] = useReducer(joyrideReducer, initialState)

  const value = { joyrideState: state, joyrideDispatch: dispatch }

  return (
    <JoyrideContext.Provider value={value}>{children}</JoyrideContext.Provider>
  )
}

export const useJoyrideContext = () => {
  const context = useContext(JoyrideContext)
  if (context === undefined) {
    throw new Error('useJoyride must be used within a JoyrideProvider')
  }
  return context
}
