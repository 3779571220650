import { useGlobalContext } from 'context/GlobalContext'
import { Modal } from '@enterprise-ui/canvas-ui-react'

export default function ModalWrapper() {
  const { globalDispatch, globalState } = useGlobalContext()
  const closeModal = (event: any) => {
    const className = event?.target?.className || ''
    const isBackdrop =
      typeof className === 'string' && className.includes('C-FocusTrap')
    if (isBackdrop && globalState?.modal?.props?.disableBackdropClose) {
      return
    }

    globalDispatch({
      payload: null,
      type: 'SET_MODAL',
    })
  }

  if (!globalState?.modal?.template) {
    return null
  }

  return (
    <Modal
      isVisible={!!globalState?.modal?.template}
      onRefuse={closeModal}
      data-testid="modal"
      size={globalState?.modal?.size || 'normal'}
    >
      <div style={{ outline: 'none' }}>
        <globalState.modal.template {...globalState.modal.props} />
      </div>
    </Modal>
  )
}
