import { ReactNode, useEffect, useMemo, useState } from 'react'
import { registerServiceWorker } from './serviceWorkerRegistrar'
import { generateContext } from 'common/generateContext'

export const SKIP_WAITING_MESSAGE = 'skipWaiting'

type UpdatesAvailableProviderValue = {
  isUpdateAvailable: boolean
  isDeferred: boolean
  setIsDeferred: (value: boolean) => void
  setUpdateAvailable: (value: boolean) => void
  updateAssets: () => void
}

type ProviderProps = {
  children: ReactNode
  debugUpdatesAvailable?: boolean
  debugIsDeferred?: boolean
  debugWaitingServiceWorker?: ServiceWorker
}

const [useContextHook, Context] =
  generateContext<UpdatesAvailableProviderValue>()
export const UpdatesAvailableContext = Context
export const useUpdatesAvailable = useContextHook

export const UpdatesAvailableProvider = ({
  children,
  debugUpdatesAvailable,
  debugIsDeferred,
  debugWaitingServiceWorker,
}: ProviderProps) => {
  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker | null>(debugWaitingServiceWorker || null)
  const [isUpdateAvailable, setUpdateAvailable] = useState(
    debugUpdatesAvailable || false,
  )
  const [isDeferred, setIsDeferred] = useState(debugIsDeferred || false)

  useEffect(() => {
    registerServiceWorker({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        setWaitingServiceWorker(registration.waiting)
        setIsDeferred(true)
        setUpdateAvailable(true)
      },
      onWaiting: (newWaitingServiceWorker: ServiceWorker) => {
        setWaitingServiceWorker(newWaitingServiceWorker)
        setUpdateAvailable(true)
      },
    })
  }, [])

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      setUpdateAvailable,
      isDeferred,
      setIsDeferred,
      updateAssets: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ action: SKIP_WAITING_MESSAGE })
        }
      },
    }),
    [isUpdateAvailable, isDeferred, waitingServiceWorker],
  )

  return (
    <UpdatesAvailableContext.Provider value={value}>
      {children}
    </UpdatesAvailableContext.Provider>
  )
}
