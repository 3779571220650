import { createContext, useContext } from 'react'

export const HOOK_USED_OUTSIDE_CONTEXT_MESSAGE = `custom useContext hook must be inside a Provider with a value.`

export const generateContext = <T extends Record<string, unknown>>() => {
  const Context = createContext<T | undefined>(undefined)

  const useCustomContext = () => {
    const ctx = useContext(Context)

    if (ctx === undefined) {
      throw new Error(HOOK_USED_OUTSIDE_CONTEXT_MESSAGE)
    }
    return ctx
  }

  return [useCustomContext, Context] as const
}
