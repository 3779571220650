import React from 'react'
import { updateItem } from './utils'
import { TransportationProfileState } from '../common/types'
type Action = (
  | { type: 'CLEAR_SELECTED_ITEMS' }
  | { type: 'UPDATE_LOCATION' }
  | { type: 'UPDATE_PRODUCT' }
  | { type: 'SET_LOCATIONS' }
  | { type: 'SET_SELECTED_ITEMS' }
) & {
  payload?: any
}

type Dispatch = (action: Action) => void

type TransportationProfileProviderProps = {
  children: React.ReactNode
  initialState?: TransportationProfileState
}

const initialState: TransportationProfileState = {
  locations: [],
  products: [],
  selectedItems: [],
}

const TransportationProfileStateContext = React.createContext<
  | {
      transportationProfileState: TransportationProfileState
      transportationProfileDispatch: Dispatch
    }
  | undefined
>(undefined)

function transportationProfileReducer(
  state: TransportationProfileState,
  action: Action,
) {
  switch (action.type) {
    case 'CLEAR_SELECTED_ITEMS':
      return {
        ...state,
        selectedItems: [],
      }
    case 'SET_LOCATIONS':
      return {
        ...state,
        locations: action?.payload?.locations || [],
        products: action?.payload?.products || [],
      }
    case 'SET_SELECTED_ITEMS':
      return { ...state, selectedItems: action.payload }
    case 'UPDATE_LOCATION':
      return {
        ...state,
        locations: updateItem(state.locations, action.payload),
      }
    case 'UPDATE_PRODUCT':
      return {
        ...state,
        products: updateItem(state.products, action.payload),
      }
    default:
      return state
  }
}

const TransportationProfileProvider = ({
  children,
}: TransportationProfileProviderProps) => {
  const [transportationProfileState, transportationProfileDispatch] =
    React.useReducer(transportationProfileReducer, initialState)

  const value = {
    transportationProfileState,
    transportationProfileDispatch,
  }

  return (
    <TransportationProfileStateContext.Provider value={value}>
      {children}
    </TransportationProfileStateContext.Provider>
  )
}

const useTransportationProfileContext = () => {
  const context = React.useContext(TransportationProfileStateContext)
  if (context === undefined) {
    throw new Error(
      'useTransportationProfile must be used within a TransportationProfileProvider',
    )
  }
  return context
}

export { TransportationProfileProvider, useTransportationProfileContext }
