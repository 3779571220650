import { useMutation, useQueryClient } from 'react-query'
import { postNewPurchaseOrderShipmentEndpoint } from '../../services/postCreateShipment'
import { sendToTmEndpoint } from '../../services/sentToTmEndpoint'
import { shipmentsModificationEndpoint } from '../../services/shipmentModifications'
import { shipmentsCancellationsEndpoint } from '../../services/shipmentCancellations'
import { shipmentsLoadModificationsEndpoint } from '../../services/loadModifications'
import { patchProductEndpoint } from '../../services/patchProduct'
import { patchLocationEndpoint } from '../../services/patchLocation'
import { postNewConsolidatorShipmentDataEndpoint } from 'services/postCreateConsolidatorShipment'

export const useCreateNewPurchaseOrderShipmentQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(postNewPurchaseOrderShipmentEndpoint, {
    onSuccess: () => {
      queryClient.invalidateQueries('purchase_orders')
      queryClient.invalidateQueries('shipments-by-po')
    },
  })
}

export const useCreateNewConsolidatorShipmentQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(postNewConsolidatorShipmentDataEndpoint, {
    onSuccess: () => {
      queryClient.invalidateQueries('purchase_orders')
      queryClient.invalidateQueries('shipments-by-po')
      queryClient.invalidateQueries('shipments-by-filters')
    },
  })
}

export const useSendShipmentToTmQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(sendToTmEndpoint, {
    onSuccess: () => {
      queryClient.invalidateQueries('shipments-by-po')
    },
  })
}

export const useShipmentsModificationQuery = () => {
  return useMutation(shipmentsModificationEndpoint)
}

export const useShipmentsCancellationsQuery = () => {
  return useMutation(shipmentsCancellationsEndpoint)
}

export const useShipmentsLoadModificationQuery = () => {
  return useMutation(shipmentsLoadModificationsEndpoint)
}

export const usePatchProductQuery = (onSuccess: any, onError: any) => {
  return useMutation(patchProductEndpoint, {
    onSuccess,
    onError,
  })
}

export const usePatchLocationQuery = (onSuccess: any, onError: any) => {
  return useMutation(patchLocationEndpoint, {
    onSuccess,
    onError,
  })
}
