import { ShipmentRowInterface } from 'common/types'
import { keys, get } from 'lodash'

export enum ShipmentAttributes {
  PickupDate = 'Pickup Date',
  PickupReasonCode = 'Pickup Reason Code',
  Carton = 'Cartons',
  TotalWt = 'Weight(Lb)',
  TotalVol = 'Cube(ft)',
  Pallet = 'Pallets',
  CommodityCode = 'Commodity Code',
  Bol = 'BOL',
  AppointmentNumber = 'Appointment #',
}

export const findInvalidBookingVersion = (
  shipments: Array<ShipmentRowInterface>,
  shipmentManagementState: any,
) => {
  if (!Array.isArray(shipments)) {
    return
  }

  return shipments.find((shipment: any) => {
    return get(
      shipmentManagementState,
      `shipmentsByPurchaseOrderKey.${shipment.purchaseOrderNumber}.${shipment.tgtBookingId}.isBookingVersionInvalid`,
    )
  })
}

export const isShipmentUpdated = (
  shipment: ShipmentRowInterface,
  field: string,
) => {
  const fieldName = field[0].toLowerCase() + field.slice(1)

  const value = get(shipment, fieldName)
  const originalValue = get(shipment, `original_${fieldName}`)

  return value !== originalValue
}

export const anyShipmentAttributeUpdated = (shipment: ShipmentRowInterface) => {
  return keys(ShipmentAttributes).some((field: string) => {
    return isShipmentUpdated(shipment, field)
  })
}
