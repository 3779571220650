import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ShipmentRowInterface } from '../../common/types'
import { SHIPMENT_STATUS } from '../../common/constants'
import { isEmpty, sortBy } from 'lodash'
import { isFailedValidation } from '../PurchaseOrders/utils'
import { isEnabledPickupDate } from '../../common/utils'

const FDC_LOCATIONS = [
  '3712',
  '3735',
  '3880',
  '3881',
  '3892',
  '3895',
  '3897',
  '3899',
]

const CONSOL_VENDOR_IDS = [4940324, 4941261, 4941460, 4966276]

dayjs.extend(utc)
dayjs.extend(timezone)

export const renderSelectAllBox = (
  shipments: Record<string, ShipmentRowInterface>,
) => {
  return !!Object.values(shipments).find(
    (row) =>
      row.currentShipmentStatus === SHIPMENT_STATUS.CONTENT_REVIEW_REQUIRED,
  )
}

export const sortedIndexStatus: { [key: string]: number } = {
  'Past Pickup': 0,
  'Content Review Required': 1,
  'On Hold for Processing': 2,
  'Retrieving Pickup Date': 3,
  'Routing In Progress': 4,
  'Awaiting Carrier Acceptance': 5,
  'Carrier Accepted, Awaiting Pickup': 6,
  Cancelled: 7,
  'Picked Up': 8,
}

export const sortShipments = (shipments: Array<ShipmentRowInterface>) => {
  return sortBy(shipments, [
    (shipment: ShipmentRowInterface) => {
      return sortedIndexStatus[shipment.currentShipmentStatus]
    },
  ])
}

export const isFDC = (destLocCode: string): boolean =>
  FDC_LOCATIONS.includes(destLocCode)

export const isCONSOL = (vendorId: number): boolean =>
  CONSOL_VENDOR_IDS.includes(vendorId)

export const getPickupDate = (
  destinationLocationCode: string,
  todaysDate: string,
) => {
  return isFDC(destinationLocationCode)
    ? { date: todaysDate, offsetDays: 1 }
    : { date: todaysDate, offsetDays: 2 }
}

export const shipmentAttributes = {
  appointmentNumber: null,
  bol: null,
  carton: null,
  commodityCode: null,
  totalVol: null,
  destLocCode: null,
  pallet: null,
  irdDate: null,
  pickupDate: null,
  totalWt: null,
}

export const isValidPickupDate = (
  pickupDate: string | null | undefined,
  leadDays: number = 2,
): boolean => {
  return (
    dayjs(pickupDate).isValid() &&
    isEnabledPickupDate(
      dayjs(pickupDate),
      dayjs().format('YYYY-MM-DD'),
      Number(dayjs().tz('America/Chicago').format('H')),
      leadDays,
      false,
    )
  )
}

export const isValidProperties = (
  dimensions: any,
  attributes: Record<string, any> = shipmentAttributes,
): boolean =>
  Object.keys(attributes)
    .map((ele) =>
      isValidProperty(
        ele,
        dimensions?.[ele] || '',
        isFDC(dimensions?.['destLocCode']?.toString()),
      ),
    )
    .every(Boolean)

export const isValidProperty = (
  field: string,
  fieldValue: any,
  isFDC: boolean,
): boolean => {
  switch (field) {
    case 'commodityCode':
    case 'destLocCode':
      return !isEmpty(fieldValue?.toString())
    case 'bol':
    case 'appointmentNumber':
      return (
        isEmpty(fieldValue) || !isFailedValidation(fieldValue, field, isFDC)
      )
    case 'carton':
    case 'totalWt':
    case 'totalVol':
    case 'pallet':
      return fieldValue > 0 && !isFailedValidation(fieldValue, field, isFDC)
    default:
      return !isFailedValidation(fieldValue, field, isFDC)
  }
}
