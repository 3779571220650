import dayjs from 'dayjs'
import arraySupport from 'dayjs/plugin/arraySupport'
import { BusinessHours } from './types'
import apiConfig from 'config/apiConfig'

// If an object has undefined value, remove it
export const cleanObject = (obj: any) => {
  if (typeof obj !== 'object') {
    return {}
  }
  return Object.keys(obj).reduce((prev: any, curr: any) => {
    if (obj[curr] === undefined) {
      return prev
    } else {
      return { ...prev, [curr]: obj[curr] }
    }
  }, {})
}

export function removeObj(obj: any, deleteKey: any) {
  let clone = Object.assign({}, obj)
  delete clone[deleteKey]
  return clone
}

export const mfeRoutes = (_env: any) => {
  return apiConfig.mfes
    ?.reduce((prev: any, curr: any) => {
      if (curr.childRoutes.length > 0) {
        const routes = curr.childRoutes.map((route: any) => {
          return {
            name: curr.name,
            prefix: curr.prefix,
            to: route.to,
            host: curr.host,
            routePath: curr.routePath,
            hostUrl: curr.hostUrl,
          }
        })
        return [...prev, ...routes]
      } else {
        return [
          ...prev,
          {
            name: curr.name,
            prefix: curr.prefix,
            to: curr.to,
            host: curr.host,
            routePath: curr.routePath,
            hostUrl: curr.hostUrl,
          },
        ]
      }
    }, [])
    .flat()
}

export interface PollInterface {
  fn: Function
  validate: Function
  interval: number
  maxAttempts: number
}

export const poll = async (config: PollInterface) => {
  const { fn, validate, interval, maxAttempts } = config

  let attempts = 0

  const executePoll = async (resolve: any, reject: any) => {
    const result = await fn()
    attempts++

    if (validate(result)) {
      return resolve(result)
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    } else {
      setTimeout(executePoll, interval, resolve, reject)
    }
  }

  return new Promise(executePoll)
}

export const getParam = (param: string) => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const query = params.get('query')

  const parsedQuery = JSON.parse(query || '{}')

  return parsedQuery[param]
}

export const isObjectOnly = (value: any) => {
  return typeof value === 'object' && !Array.isArray(value)
}

export const getShipmentManagementFiltersFromUrl = (vendors?: any) => {
  return {
    vendorIds:
      getParam('vendorIds') || vendors?.map((v: any) => v.vendorId) || [],
    dateFrom:
      getParam('dateFrom') || dayjs().subtract(60, 'day').format('YYYY-MM-DD'),
    dateTo: getParam('dateTo') || dayjs().add(60, 'day').format('YYYY-MM-DD'),
    purchaseOrders: getParam('purchaseOrders'),
    shipmentIds: getParam('shipmentIds'),
    loadIds: getParam('loadIds'),
    tmLocIds: getParam('tmLocIds'),
    departmentIds: getParam('departmentIds'),
    truckLoadOptimizationRefIds: getParam('truckLoadOptimizationRefIds'),
    vendorLocationRelationship: getParam('vendorLocationRelationship'),
  }
}

export const getDateByNthWeekOfMonthAndDay = (
  year: number,
  month: number,
  weekOfMonth: number,
  weekday: number,
) => {
  if (isNaN(year) || isNaN(month) || isNaN(weekOfMonth) || isNaN(weekday)) {
    throw new Error('Missing date param')
  }
  // Get the weekday of the month, which is also the offset
  const monthStartWeekDay = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD').day()
  // January is 0 months, in dayjs therefore -1 offset
  // Sunday is 0 weekday, in dayjs
  dayjs.extend(arraySupport)
  return dayjs([year, month - 1, 1])
    .add(weekOfMonth, 'week')
    .subtract(monthStartWeekDay, 'day')
    .add(weekday, 'day')
    .format('YYYY-MM-DD')
}

export const getHolidays = (year: number) => {
  if (!year) {
    throw new Error('No Year Provided')
  }
  return [
    // XMAS
    `${year}-12-25`,
    //Thanks Giving
    getDateByNthWeekOfMonthAndDay(year, 11, 3, 4),
  ]
}
export const getVendorLocationHolidays = (
  holidays: string[],
  closureDates: [],
) => {
  if (closureDates!.length > 0) {
    return holidays.concat(closureDates)
  } else {
    return holidays
  }
}

export const getLocationCloseDays = (
  businessHours: Array<BusinessHours> | undefined,
) => {
  let closeDays: number[] = []
  businessHours?.forEach((businessHour) => {
    if (businessHour.openTime == null && businessHour.closeTime == null) {
      switch (businessHour.day) {
        case 'MON':
          closeDays.push(1)
          break
        case 'TUE':
          closeDays.push(2)
          break
        case 'WED':
          closeDays.push(3)
          break
        case 'THU':
          closeDays.push(4)
          break
        case 'FRI':
          closeDays.push(5)
          break
        case 'SAT':
          closeDays.push(6)
          break
        case 'SUN':
          closeDays.push(0)
          break
        default:
          break
      }
    }
  })

  return closeDays
}

export const getOffsetDays = (
  todaysDate: string,
  holidays: Array<string>,
  leadDays: number,
  currentHour: number,
  consolPickupDate: boolean = false,
) => {
  if (!todaysDate || !Array.isArray(holidays)) {
    throw new Error('Missing date or holiday params')
  }

  let businessDays = 0
  let offsetDays = 0
  let currentLeadDays = leadDays
  /*
   * Vendor based pickup uses an 11 am cutoff time, while consol shipments use an 8 am cutoff time
   */
  const timeCutoff = consolPickupDate ? 8 : 11

  // If the current time is before 11AM, subtract a lead day
  if (currentHour < timeCutoff) {
    currentLeadDays = currentLeadDays - 1
  }

  // Keep accumulating offset days until we have two business days accumulated that are not weekend or holidays
  while (businessDays <= currentLeadDays) {
    // Todays Date
    const offsetDate = dayjs(todaysDate)
      .add(offsetDays, 'day')
      .format('YYYY-MM-DD')

    // The day of the week
    const offsetDay = dayjs(offsetDate).day()

    // Add an offset day for every loop
    offsetDays += 1

    // If not weekend or holiday do not accumulate business day
    if (offsetDay === 0 || offsetDay === 6 || holidays.includes(offsetDate)) {
      businessDays += 0
    } else {
      businessDays += 1
    }
  }
  // return the offset days that were accumulated
  return offsetDays
}

export const isEnabledPickupDate = (
  date: dayjs.Dayjs,
  todaysDate: string,
  currentHour: number,
  leadDays: number,
  consolPickupDate: boolean = false,
  businessHours?: Array<BusinessHours>,
  businessHrsBasedPickup?: boolean,
  enableDates?: Array<string>,
  closureDates?: string[],
  firstPickupDate?: string,
): boolean => {
  if (!date || !todaysDate) {
    throw new Error('Missing calendar date or todays day param')
  }
  // TODO will need to get holidays from VMM in the future
  const year = date.year()
  const day = date.day()
  const holidays = getHolidays(year)
  const calendarDate = dayjs(date).format('YYYY-MM-DD')
  const offsetDays = getOffsetDays(
    todaysDate,
    holidays,
    leadDays,
    currentHour,
    consolPickupDate,
  )
  const offsetDateFromToday = dayjs(todaysDate)
    .add(offsetDays, 'day')
    .format('YYYY-MM-DD')
  const vendorCloseDays = getLocationCloseDays(businessHours)

  const formattedFirstPickupDate = dayjs(firstPickupDate).format('YYYY-MM-DD')
  if (firstPickupDate && calendarDate < formattedFirstPickupDate) {
    return false
  }

  let isHoliday
  // add location specified holidays if exists on top of 2 default holidays (X-mas and Thanksgiving)
  if (closureDates != null) {
    const locationHolidays = getVendorLocationHolidays(
      holidays,
      closureDates as [],
    )
    isHoliday = locationHolidays.includes(dayjs(date).format(`${year}-MM-DD`))
  } else {
    isHoliday = holidays.includes(dayjs(date).format(`${year}-MM-DD`))
  }

  if (enableDates?.includes(calendarDate)) {
    return true
  }

  if (offsetDateFromToday > calendarDate) {
    return false
  }

  if (isHoliday) {
    return false
  }
  // will open up calendar for everyday of the week except specified holidays when business hours returns empty OR businessHrsBasedPickup flag set to false
  if (businessHours?.length === 0 || businessHrsBasedPickup === false) {
    return true
  }

  if (businessHrsBasedPickup) {
    if (vendorCloseDays.includes(day)) {
      return false
    }
  }

  return true
}

export const progressPercentageBasedOnPickupDateAndDrivingDays = (
  pickupDate: Date | undefined,
  drivingDays: number,
) => {
  const percentage = (dayjs().diff(pickupDate, 'd') / drivingDays) * 100
  return percentage <= 100 ? Math.round(percentage) : 100
}

export const purchaseOrderKey = (data: any) =>
  `${data.purchaseOrderNumber || data.purchaseOrder}-${data.department}`

export const hyphenForFalsey = (input?: string): string => {
  if (input === undefined || input === null) {
    return '-'
  }
  return input
}

/**
  Return an array of length 366, filled with strings representing dates in the form YYYY/MM/DD.

  Takes in an optional initial dayjs.Dayjs to start the array. If initial dayjs.Dayjs is not passed, default value is set to today.
 **/
export const datesForTheComingYear = (
  initDate: dayjs.Dayjs = dayjs(),
): string[] => {
  return Array(366)
    .fill(0)
    .map((_, i) => dayjs(initDate).add(i, 'day').format('YYYY/MM/DD'))
}

export const capitalizeFirstLetterOfEachWord = (phrase?: string) =>
  phrase
    ? phrase
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    : phrase

export const deepCopy = (obj: Object) => JSON.parse(JSON.stringify(obj))
