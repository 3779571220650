import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { cleanObject } from 'common/utils'

export interface CreatedShipmentRequest {
  appointment_number?: string | null
  bill_of_lading?: string | null
  carton_qty: number | null
  commodity_code: string | null
  dept: number
  dest_loc_code: number | null
  origin: {
    code: string
    country_code: string
    state: string
    zip: string
  }
  inventory_ready_date?: Date | string | null
  origin_logistics_group: string
  pallet_spaces: number | null
  po: string
  total_volume: number | null
  total_weight: number | null
  vendor_id: number
  vmm_location_id: number
}
export const postNewPurchaseOrderShipmentEndpoint = async (
  createdShipment: CreatedShipmentRequest,
) => {
  const removedUndefinedcreatedShipment = cleanObject(createdShipment)
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/new_shipment?key=${key}`

  let body = {
    weight_uom: 'POUND',
    volume_uom: 'CU.FT',
    domestic_we_pay: true,
    create_user: 'Should come from backend token',
    ...removedUndefinedcreatedShipment,
  }
  return await axios({
    method: 'post',
    url: endpoint,
    data: body,
  })
}
