import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { sortBy } from 'lodash'

export const transformVendors = (data: any) => {
  const vendorDetails = data?.vendor_details || []
  const transformedVendors: { vendorId: number; vendorName: string }[] =
    vendorDetails.map((obj: any) => {
      return {
        vendorId: obj.vendor_id,
        vendorName: `${obj.vendor_name} (${obj.vendor_id})`,
      }
    })

  return {
    businessPartnerName: data.business_partner_name,
    ids: vendorDetails.map((item: any) => {
      return item.vendor_id
    }),
    vendors: sortBy(transformedVendors, ['vendorName']),
  }
}

export const vendorsEndpoint = async () => {
  const { key, url } = await getUiServicesEndpoint()
  return `${url}/v1/users?key=${key}`
}

export const fetchVendors = async () => {
  const endpoint = await vendorsEndpoint()
  return axios({
    method: 'get',
    url: endpoint,
  }).then((res) => res.data)
}
