import { useNavigate } from 'react-router-dom'
import ShipmentsActions from 'components/ShipmentsActions'
import PageNotFoundImage from '../../media/404.svg'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import React from 'react'

const PageNotFound = (_props: any | React.ReactNode) => {
  const navigate = useNavigate()
  return (
    <Grid.Container
      direction="column"
      justify="center"
      align="center"
      style={{ width: '100%', height: '80vh' }}
    >
      <Grid.Item sx={{ marginLeft: '7.5em' }}>
        <PageNotFoundImage />
      </Grid.Item>
      <Grid.Item>
        <div className="hc-fs-md hc-align-center">
          Oops! Looks like you are a bit off Target, because this page was not
          found.
          <br /> Perhaps you should go home and practice aiming.
        </div>
      </Grid.Item>
      <Grid.Item sx={{ marginTop: '2em' }}>
        <Button
          variant="outlined"
          className="hc-ml-md"
          onClick={() => navigate('/')}
        >
          Go Home
        </Button>
      </Grid.Item>
      <ShipmentsActions />
    </Grid.Container>
  )
}

export default PageNotFound
