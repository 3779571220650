import { Heading } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'

const Acknowledge = styled.div`
  background: #fffde7;
  padding: 12px;
  border-radius: 4px;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin-bottom: 14px;
  }
  span {
    font-size: 14px;
  }
`

export const Acknowledgement = (_props: any) => {
  return (
    <Acknowledge>
      <Heading gutterBottom size={6} className="hc-fs-lg hc-mb-normal">
        Shipment Cancellation Warning
      </Heading>
      <ul>
        <li>
          One or more shipments is being cancelled. Cancellations cannot be
          undone.
        </li>
      </ul>
    </Acknowledge>
  )
}
