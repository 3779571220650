import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { ReceivingLocation } from '../common/types'

export const transformPurchaseOrderDetails = (data: any) => {
  const receivingLocations: Array<ReceivingLocation> = data ?? []
  return Array.from(receivingLocations).map((location: ReceivingLocation) => {
    return location.id
  })
}

export const fetchDestinationsForPoAndDeptEndpoint = async (
  po?: number | null,
  dept?: number | null,
) => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/receiving_locations?purchase_order_id=${po ?? ''}&department=${dept ?? ''}&key=${key}`

  return axios({
    method: 'get',
    url: endpoint,
  })
}
